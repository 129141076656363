








import { defineComponent, type PropType } from '@vue/composition-api'
import { parse } from 'date-fns'
import DateSelector from 'src/components/Workplace/DateSelector.vue'
import { type DateRange } from '../../values/DateRange'

export default defineComponent({
  components: {
    DateSelector,
  },
  props: {
    value: {
      type: Object as PropType<DateRange>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, context) {
    const handleChange = (startDate: string, endDate: string) => {
      context.emit('input', {
        startDate: parse(startDate, 'yyyy-MM-dd', new Date()),
        endDate: parse(endDate, 'yyyy-MM-dd', new Date()),
      })
    }

    return {
      handleChange,
    }
  },
})
