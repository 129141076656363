












































































































import Vue from 'vue';
import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { useTimetableActivityMasters } from '../../composables/useTimetableActivityMasters';
import { formatTime, formatNumber, formatFixedDecimal } from '../../utils/filters';
import {
  APPROPRIATION_DATE_DIFF_MAX,
  APPROPRIATION_DATE_DIFF_MIN,
  APPROPRIATION_DATE_DIFF_ANCHOR,
  APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS,
  APPROPRIATION_DATE_RANGE,
  APPROPRIATION_DATE_RANGE_OPTIONS,
} from '../../const';
import TimetableMasterListRow from './TimetableMasterListRow.vue';
import TimetableMasterListCell from './TimetableMasterListCell.vue';
import TimetableMasterListControls from './TimetableMasterListControls.vue';
import { getTimetableMasterLogimeterActivitiesString } from './getTimetableMasterLogimeterActivitiesString';

export default defineComponent({
  filters: {
    formatTime,
    formatNumber,
    formatFixedDecimal,
  },
  components: {
    TimetableMasterListRow,
    TimetableMasterListCell,
    TimetableMasterListControls,
  },
  props: {
    timetableMaster: {
      type: Object as PropType<TimetableMaster>,
      required: true,
    },
    onEdit: {
      type: Function as PropType<(timetableMaster: TimetableMaster) => unknown>,
      required: true,
    },
    onDelete: {
      type: Function as PropType<(timetableMaster: TimetableMaster) => unknown>,
      required: true,
    },
  },
  setup(props, context) {
    const root = context.root as Vue;
    const { timetableActivityMasters } = useTimetableActivityMasters();
    const timetableMasterLogimeterActivitiesString = computed(() =>
      getTimetableMasterLogimeterActivitiesString(props.timetableMaster, timetableActivityMasters.value),
    );
    const handleClick = () => {
      root.$router.push({
        name: 'WorkplaceOperationOverview',
        params: {
          date: 'today',
        },
        query: {
          timetable_master_id: String(props.timetableMaster.id),
          display: '1',
        },
      });
    };

    // appropriation_dt_diff_startとappropriation_dt_diff_endから「特定の計上日に絞り込み」を算出する
    const appropriationDate = computed(() => {
      const appropriation_dt_diff_start = props.timetableMaster.appropriation_dt_diff_start;
      const appropriation_dt_diff_end = props.timetableMaster.appropriation_dt_diff_end;

      // appropriation_dt_diff_start:null, appropriation_dt_diff_end:null or
      // appropriation_dt_diff_start:-32, appropriation_dt_diff_end:32の時
      // 未設定なので「特定の計上日に絞り込み」は設定なしと判断する
      if (
        (appropriation_dt_diff_start === null && appropriation_dt_diff_end === null) ||
        (appropriation_dt_diff_start === APPROPRIATION_DATE_DIFF_MIN &&
          appropriation_dt_diff_end === APPROPRIATION_DATE_DIFF_MAX)
      ) {
        return '';
      }
      // appropriation_dt_diff_startとappropriation_dt_diff_endが同じ値の時
      // 「特定の計上日に絞り込み」左プルダウン：選択済、「特定の計上日に絞り込み」右プルダウン：- と判断する
      if (appropriation_dt_diff_start === appropriation_dt_diff_end) {
        const appropriationDateDiffAnchorOption = APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS.find(
          (v) => v.value === appropriation_dt_diff_start,
        );
        return appropriationDateDiffAnchorOption!.label;
      }

      let appropriationDateDiffAnchorValue: number = 0;
      let appropriationDateRangeValue: number = 0;
      if (Object.values(APPROPRIATION_DATE_DIFF_ANCHOR).includes(appropriation_dt_diff_start!)) {
        appropriationDateDiffAnchorValue = appropriation_dt_diff_start!;
        appropriationDateRangeValue =
          appropriation_dt_diff_end! > 0
            ? APPROPRIATION_DATE_RANGE.ON_AND_AFTER
            : APPROPRIATION_DATE_RANGE.ON_AND_BEFORE;
      } else {
        appropriationDateDiffAnchorValue = appropriation_dt_diff_end!;
        appropriationDateRangeValue =
          appropriation_dt_diff_start! > 0
            ? APPROPRIATION_DATE_RANGE.ON_AND_AFTER
            : APPROPRIATION_DATE_RANGE.ON_AND_BEFORE;
      }
      const appropriationDateDiffAnchorOption = APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS.find(
        (v) => v.value === appropriationDateDiffAnchorValue,
      );
      const AppropriationDateRangeOption = APPROPRIATION_DATE_RANGE_OPTIONS.find(
        (v) => v.value === appropriationDateRangeValue,
      );
      return `${appropriationDateDiffAnchorOption!.label} ${AppropriationDateRangeOption!.label}`;
    });

    return {
      timetableMasterLogimeterActivitiesString,
      handleClick,
      appropriationDate,
    };
  },
});
