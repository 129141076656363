import { BudgetGroupPlanBoardMisc } from 'src/models/budgetGroupPlanBoardMisc';
import { MacroOperationSegment } from 'src/models/macroOperationSegment';
import { parseYmdDate } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import { MacroOperationResponse } from './api/macroOperationResponse';
import { convertMacroOperationMasterResponse, MacroOperationMaster } from './macroOperationMaster';

export type MacroOperation = {
  id: number | null;
  macro_operation_master_id: number;
  dt: Date;
  planned_initial_quantity: number | null;
  planned_final_quantity: number | null;
  planned_productivity: number | null;
  scheduled_attendance_hour: number;
  macro_operation_master: MacroOperationMaster | null;
  actual_quantity: number | null;
  actual_working_hours: number | null;
  actual_used_hours: number | null;
  actual_productivity: number | null;
  required_working_hours: number | null;
};

export type DailyPlanBoard = {
  total_man_hours: number | null;
};

export type MacroOperationIndexResultItem = {
  // workplace_extensionはstoreから取る
  dt: Date;
  budget_group_plan_board_misc: BudgetGroupPlanBoardMisc | null;
  macro_operations: MacroOperation[];
  macro_operation_segments: MacroOperationSegment[];
};

export type MacroOperationIndexResult = MacroOperationIndexResultItem[];

export function convertMacroOperationResponse(response: MacroOperationResponse): MacroOperation {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
    scheduled_attendance_hour: Number(response.scheduled_attendance_hour),
    actual_working_hours: isExist(response.actual_working_hours) ? Number(response.actual_working_hours) : null,
    actual_used_hours: isExist(response.actual_used_hours) ? Number(response.actual_used_hours) : null,
    macro_operation_master: isExist(response.macro_operation_master)
      ? convertMacroOperationMasterResponse(response.macro_operation_master)
      : null,
    planned_productivity: isExist(response.planned_productivity) ? Number(response.planned_productivity) : null,
  };
}
