import Vue from 'vue';
import { defineComponent, onMounted, reactive, SetupContext, watch } from '@vue/composition-api';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import staffApi from 'src/apis/staff';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import workplaceExtensionApi from 'src/apis/workplace_masters/workplace_extension';
import { StaffGender, StaffGenderSelectOptions, StaffType, StaffTypeSelectOptions } from 'src/consts';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { notifyError1 } from 'src/hooks/notificationHook';
import { BudgetGroup } from 'src/models/budgetGroup';
import { Staff } from 'src/models/staff';
import { StaffWorkSchedule } from 'src/models/staffWorkSchedule';
import { TimetableMaster } from 'src/models/timetableMaster';
import { unpackTimeIntegerToStringFormat } from 'src/util/datetime';
import { StaffOverviewSearchState, StaffOverviewState } from './types';
import { add, sub } from 'date-fns';

const getStartDate = (date: Date | null = null): Date => {
  if (!date) {
    date = new Date();
    date = sub(date, { months: 5 });
  }
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getEndDate = (date: Date | null = null): Date => {
  if (!date) {
    date = new Date();
  }
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const setupSearchState = (context: SetupContext): StaffOverviewSearchState => {
  const root = context.root as Vue;

  const searchState: StaffOverviewSearchState = reactive({
    workplaceId: root.$route.params.workplaceId,
    startDate: getStartDate(),
    endDate: getEndDate(),
    selectedMonths: [],
    budgetGroup: null,
    budgetGroups: [],
    staff: null,
    staffs: [],
    isLoading: false,
  });

  return searchState;
};

const setupState = (): StaffOverviewState => {
  const state: StaffOverviewState = reactive({
    isReady: false,
    staff: null,
    workplaceExtension: null,
    staffWorkSchedules: [],
    staffMonthlySummaries: null,
    attendanceStatus: null,
  });

  return state;
};

type Day = {
  key: number;
  label: string;
};

export default defineComponent({
  setup(_props, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, 'スタッフ 特性');
    const searchState = setupSearchState(context);
    const state = setupState();
    const DAYS: Day[] = [
      { key: 1, label: '月' },
      { key: 2, label: '火' },
      { key: 3, label: '水' },
      { key: 4, label: '木' },
      { key: 5, label: '金' },
      { key: 6, label: '土' },
      { key: 0, label: '日' },
    ];

    onMounted(async () => {
      await ensureUserAndMasters(context);
      searchState.budgetGroups = await budgetGroupApi.index(searchState.workplaceId);
      const foundBudgetGroup = searchState.budgetGroups.find((budgetGroup) => {
        return budgetGroup.id === Number(root.$route.query.budget_group_id);
      });
      searchState.budgetGroup = foundBudgetGroup ?? searchState.budgetGroups[0] ?? null;

      if (foundBudgetGroup === undefined) {
        return;
      }

      const query_staff_id = root.$route.query.staff_id;
      if (query_staff_id !== null && query_staff_id !== undefined && Number(query_staff_id) > 0) {
        await setupSearchStateStaffs();
        if (!searchState.staff || searchState.staff.id !== Number(query_staff_id)) {
          return;
        }
        renderStaffOverview();
      }
    });

    const setupSearchStateStaffs = async (): Promise<void> => {
      if (searchState.budgetGroup === null) {
        return;
      }

      searchState.staffs = await fetchStaffs(Number(searchState.workplaceId), searchState.budgetGroup);

      searchState.staffs.sort(sortKanaName);
      searchState.staffs.forEach((staff) => {
        return staff.staff_skills.sort((a, b) => a.priority - b.priority);
      });

      const foundStaff = searchState.staffs.find((staff) => {
        return staff.id === Number(root.$route.query.staff_id);
      });

      searchState.staff = foundStaff ?? searchState.staffs[0] ?? null;
    };

    const fetchStaffs = async (workplaceId: number, budgetGroup: BudgetGroup): Promise<Staff[]> => {
      return await staffExtensionApi.staffs({
        workplaceId,
        params: {
          staff_number: null,
          staff_id: null,
          budget_group_id: budgetGroup.id,
          staff_agency_id: null,
          staff_label_id: null,
          is_enabled: true,
        },
      });
    };

    const checkDate = (): boolean => {
      if (searchState.startDate > searchState.endDate) {
        notifyError1(root, '開始年月は終了年月より前の日付にしてください。');
        return false;
      }

      if (sub(searchState.endDate, { months: 6 }) > searchState.startDate) {
        notifyError1(root, '指定できる最大期間は6ヵ月です。');
        return false;
      }

      return true;
    };

    const setStaffWorkSchedules = async (): Promise<void> => {
      state.staffWorkSchedules = await staffApi.getStaffWorkSchedules({
        workplaceId: searchState.workplaceId,
        staffId: state.staff!.id,
      });
    };

    const setTotalMonthlySummaries = async (): Promise<void> => {
      state.staffMonthlySummaries = await staffApi.getStaffMonthlySummaries({
        workplaceId: searchState.workplaceId,
        staffId: state.staff!.id,
        params: {
          start_date: searchState.startDate,
          end_date: searchState.endDate,
        },
      });
    };

    const setAttendanceStatus = async (): Promise<void> => {
      state.attendanceStatus = await staffApi.getAttendanceStatus({
        workplaceId: searchState.workplaceId,
        staffId: state.staff!.id,
        params: {
          start_date: searchState.startDate,
          end_date: searchState.endDate,
        },
      });
    };

    const setSelectedMonths = (): void => {
      searchState.selectedMonths = [];
      const date = new Date(searchState.startDate.getFullYear(), searchState.startDate.getMonth(), 1);
      while (date < searchState.endDate) {
        searchState.selectedMonths.push(date.getMonth() + 1);
        date.setMonth(date.getMonth() + 1);
      }
    };

    const renderStaffOverview = async (): Promise<void> => {
      if (!checkDate()) {
        return;
      }

      searchState.isLoading = true;
      state.isReady = false;
      try {
        state.staff = searchState.staff;
        state.workplaceExtension = await workplaceExtensionApi.show(searchState.workplaceId);
        await setStaffWorkSchedules();
        await setTotalMonthlySummaries();
        await setAttendanceStatus();
        setSelectedMonths();
        state.isReady = true;
      } catch (err: any) {
        notifyError1(root, 'データの表示に失敗しました。');
      } finally {
        searchState.isLoading = false;
      }
    };

    // 数値→時刻に変換
    const timeIntegerToString = (time: number): string | null => {
      if (time === null) {
        return null;
      }

      const formatType: number = 1;
      return unpackTimeIntegerToStringFormat(time, formatType);
    };

    // 浮動小数点数→整数(カンマあり)に変換
    const decimalToInteger = (decimal: number): string => {
      if (!decimal) {
        return '0';
      }

      return Math.round(decimal).toLocaleString();
    };

    // 性別名表示
    const getGenderName = (genderNum: StaffGender): string => {
      return StaffGenderSelectOptions.find((gender) => gender.id === genderNum)?.name ?? '';
    };

    // スタッフ区分名表示
    const getStaffTypeName = (staffTypeNum: StaffType): string => {
      return StaffTypeSelectOptions.find((gender) => gender.id === staffTypeNum)?.name ?? '';
    };

    // 各曜日に対応するStaffWorkScheduleを取得
    const getStaffWorkSchedule = (dayOfWeek: number): StaffWorkSchedule | null => {
      return (
        state.staffWorkSchedules.find((staffWorkSchedule) => staffWorkSchedule.day_of_week === Number(dayOfWeek)) ??
        null
      );
    };

    // 各priorityに対するStaffSkillのTimetableMasterを取得
    const getStaffSkillTimetableMaster = (priority: number): TimetableMaster | null => {
      const staff_skill =
        state.staff?.staff_skills.find((staff_skill) => staff_skill.priority === Number(priority)) ?? null;

      if (!staff_skill) {
        return null;
      }

      return staff_skill.timetable_master ?? null;
    };

    // 姓かな+名かなの降順にする
    const sortKanaName = (a: Staff, b: Staff) => {
      const nameA = a.family_name_kana + a.first_name_kana;
      const nameB = b.family_name_kana + b.first_name_kana;

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      }
      return 0;
    };

    watch(
      () => searchState.budgetGroup,
      async () => {
        await setupSearchStateStaffs();
      },
    );

    watch(
      () => searchState.startDate,
      (newStartDate, oldStartDate) => {
        if (newStartDate.getTime() !== oldStartDate.getTime()) {
          searchState.startDate = getStartDate(searchState.startDate);
          if (sub(searchState.endDate, { months: 6 }) > newStartDate) {
            searchState.endDate = add(newStartDate, { months: 5 });
          }
        }
      },
    );

    watch(
      () => searchState.endDate,
      (newEndDate, oldEndDate) => {
        if (newEndDate.getTime() !== oldEndDate.getTime()) {
          searchState.endDate = getEndDate(searchState.endDate);
          if (sub(newEndDate, { months: 6 }) > searchState.startDate) {
            searchState.startDate = sub(newEndDate, { months: 5 });
          }
        }
      },
    );

    return {
      searchState,
      state,
      DAYS,
      renderStaffOverview,
      timeIntegerToString,
      decimalToInteger,
      getGenderName,
      getStaffTypeName,
      getStaffWorkSchedule,
      getStaffSkillTimetableMaster,
    };
  },
});
