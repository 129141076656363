import { createInjection } from 'src/util/createInjection'
import { DailyTaskData } from '../types'
import { useDisplayConditions } from './useDisplayConditions'
import { useSearchConditions } from './useSearchConditions'
import macroOperationApi from 'src/apis/macroOperation'
import { useNotification } from 'src/composables/useNotification'
import { isExist } from 'src/util/isExist'
import { useMonthlyPlanOverview } from './useMonthlyPlanOverview'
import { useMonthlyPlan } from './useMonthlyPlan'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { MacroOperationIndexResult } from 'src/models/macroOperation'
import { convertToHeadcount, convertToManHours } from '../monthlyPlanHelper'

const DATE_FORMAT = 'yyyy-MM-dd'

type InjectionValue = {
  macroOperationIndex: (dt: Date, budgetGroupId: number) => Promise<MacroOperationIndexResult>
  updateDayPlanData: (taskData: DailyTaskData, dayIndex: number) => Promise<void>
  updateDayActualData: (taskData: DailyTaskData, dayIndex: number) => Promise<void>
}

const { provide, inject } = createInjection<InjectionValue>('useMacroOperation')

export function useMacroOperationProvider(): void {
  const { budgetGroup, workplaceId } = useSearchConditions()
  const { state: displayState } = useDisplayConditions()
  const { updateOverviewState } = useMonthlyPlanOverview()
  const { notifyError, notifySuccess } = useNotification()
  const { state, calculateDailyPlan } = useMonthlyPlan()

  const macroOperationIndex = async(dt: Date, budgetGroupId: number) => {
    const startDate = startOfMonth(dt)
    const endDate = endOfMonth(dt)

    return await macroOperationApi.macroOperationIndex({
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      start_date: format(startDate, DATE_FORMAT),
      end_date: format(endDate, DATE_FORMAT),
    })
  }

  /**
   * 月間ボードの予定更新
   */
  const updateDayPlanData = async(taskData: DailyTaskData, dayIndex: number) => {
    if (!budgetGroup.value) { return }
    if (!isExist(state.isReady)) { return }
    if (!isExist(taskData.taskId)) { return }
    const taskIndex = state.tasks.findIndex(item => item.id === taskData.taskId)
    const macroOperationMaster = state.tasks[taskIndex]
    if (!isExist(macroOperationMaster)) { return }

    try {
      const planned_productivity = taskData.plannedProductivity ?? macroOperationMaster.productivity
      const res = await macroOperationApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: state.targetDates[dayIndex],
        planned_initial_quantity: taskData.plannedInitialQuantity,
        planned_final_quantity: taskData.plannedFinalQuantity,
        planned_productivity,
        macro_operation_master_id: taskData.taskId,
      })
      const task = state.dailyPlans[dayIndex].tasks[taskIndex];
      task.plannedProductivity = res.planned_productivity
      task.requiredManHours = res.required_working_hours ?? 0
      const calculated = calculateDailyPlan(state.dailyPlans[dayIndex])
      state.dailyPlans[dayIndex].requiredManHours = calculated.requiredManHours
      state.dailyPlans[dayIndex].regularPlannedManHours = calculated.regularPlannedManHours
      state.dailyPlans[dayIndex].regularExcessOrDeficiency = calculated.regularExcessOrDeficiency
      state.dailyPlans[dayIndex].totalSpot = calculated.totalSpot
      state.dailyPlans[dayIndex].totalExcessOrDeficiency = calculated.totalExcessOrDeficiency
      updateOverviewState(state)
      notifySuccess('月間計画を更新しました')
    } catch (err: any) {
      notifyError('月間計画の更新に失敗しました', { cause: err })
    }
  }

  /**
   * 月間計画の実績更新
   */
  const updateDayActualData = async(taskData: DailyTaskData, dayIndex: number) => {
    if (!budgetGroup.value) { return }
    if (!isExist(state.isReady)) { return }
    if (!isExist(taskData.taskId)) { return }
    const taskIndex = state.tasks.findIndex(item => item.id === taskData.taskId)
    const macroOperationMaster = state.tasks[taskIndex]
    if (!isExist(macroOperationMaster)) { return }

    if (displayState.isDisplayedManHour) {
      state.dailyPlans[dayIndex].tasks[taskIndex].actualHeadcount = convertToHeadcount(state.dailyPlans[dayIndex].tasks[taskIndex].actualManHours)
    } else {
      state.dailyPlans[dayIndex].tasks[taskIndex].actualManHours = convertToManHours(state.dailyPlans[dayIndex].tasks[taskIndex].actualHeadcount)
    }

    try {
      const res = await macroOperationApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: state.actualDates[dayIndex],
        actual_quantity: taskData.actualQuantity,
        actual_working_hours: taskData.actualManHours,
        actual_used_hours: taskData.actualUsedHours,
        macro_operation_master_id: taskData.taskId
      })
      const task = state.dailyPlans[dayIndex].tasks.find((task) => task.taskId === res.macro_operation_master_id)
      if (isExist(task)) {
        task.actualProductivity = res.actual_productivity
      }
      const calculated = calculateDailyPlan(state.dailyPlans[dayIndex])
      state.dailyPlans[dayIndex].totalActualManHours = calculated.totalActualManHours
      updateOverviewState(state)
      notifySuccess('月間計画を更新しました')
    } catch (err: any) {
      notifyError('月間計画の更新に失敗しました', { cause: err })
    }
  }

  provide({
    macroOperationIndex,
    updateDayPlanData,
    updateDayActualData,
  })
}

export function useMacroOperation(): InjectionValue {
  return inject()
}
