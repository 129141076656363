import { reactive, InjectionKey, toRefs, Ref } from '@vue/composition-api';

type State = {
  isDisplayBulkCreateModal: boolean;
  isDisplayReceptionShiftModal: boolean;
  isDisplaySettingDisplayItemModal: boolean;
  isDisplayConditionSettingModal: boolean;
  isDisplayShiftRegisterModal: boolean;
  isDisplayShiftBulkEditModal: boolean;
  isDisplayYearSummaryModal: boolean;
  isDisplayUpdateMonthlySummaryModal: boolean;
};

type ModalDisplay = {
  openBulkCreateModal: () => void;
  closeBulkCreateModal: () => void;
  openReceptionShiftModal: () => void;
  closeReceptionShiftModal: () => void;
  openSettingDisplayItemModal: () => void;
  closeSettingDisplayItemModal: () => void;
  openDisplayConditionSettingModal: () => void;
  closeDisplayConditionSettingModal: () => void;
  openShiftRegisterModal: () => void;
  closeShiftRegisterModal: () => void;
  openShiftBulkEditModal: () => void;
  closeShiftBulkEditModal: () => void;
  openYearSummaryModal: () => void;
  closeYearSummaryModal: () => void;
  openUpdateMonthlySummaryModal: () => void;
  closeUpdateMonthlySummaryModal: () => void;
  isDisplayBulkCreateModal: Ref<boolean>;
  isDisplayReceptionShiftModal: Ref<boolean>;
  isDisplaySettingDisplayItemModal: Ref<boolean>;
  isDisplayConditionSettingModal: Ref<boolean>;
  isDisplayShiftRegisterModal: Ref<boolean>;
  isDisplayShiftBulkEditModal: Ref<boolean>;
  isDisplayYearSummaryModal: Ref<boolean>;
  isDisplayUpdateMonthlySummaryModal: Ref<boolean>;
};

export const useModal = (): ModalDisplay => {
  const state = reactive<State>({
    isDisplayBulkCreateModal: false,
    isDisplayReceptionShiftModal: false,
    isDisplaySettingDisplayItemModal: false,
    isDisplayConditionSettingModal: false,
    isDisplayShiftRegisterModal: false,
    isDisplayShiftBulkEditModal: false,
    isDisplayYearSummaryModal: false,
    isDisplayUpdateMonthlySummaryModal: false,
  });

  const openBulkCreateModal = (): void => {
    state.isDisplayBulkCreateModal = true;
  };

  const closeBulkCreateModal = (): void => {
    state.isDisplayBulkCreateModal = false;
  };

  const openReceptionShiftModal = (): void => {
    state.isDisplayReceptionShiftModal = true;
  };

  const closeReceptionShiftModal = (): void => {
    state.isDisplayReceptionShiftModal = false;
  };

  const openSettingDisplayItemModal = (): void => {
    state.isDisplaySettingDisplayItemModal = true;
  };

  const closeSettingDisplayItemModal = (): void => {
    state.isDisplaySettingDisplayItemModal = false;
  };

  const openDisplayConditionSettingModal = (): void => {
    state.isDisplayConditionSettingModal = true;
  };

  const closeDisplayConditionSettingModal = (): void => {
    state.isDisplayConditionSettingModal = false;
  };

  const openShiftRegisterModal = (): void => {
    state.isDisplayShiftRegisterModal = true;
  };

  const closeShiftRegisterModal = (): void => {
    state.isDisplayShiftRegisterModal = false;
  };

  const openShiftBulkEditModal = (): void => {
    state.isDisplayShiftBulkEditModal = true;
  };

  const closeShiftBulkEditModal = (): void => {
    state.isDisplayShiftBulkEditModal = false;
  };

  const openYearSummaryModal = (): void => {
    state.isDisplayYearSummaryModal = true;
  };

  const closeYearSummaryModal = (): void => {
    state.isDisplayYearSummaryModal = false;
  };

  const openUpdateMonthlySummaryModal = (): void => {
    state.isDisplayUpdateMonthlySummaryModal = true;
  };

  const closeUpdateMonthlySummaryModal = (): void => {
    state.isDisplayUpdateMonthlySummaryModal = false;
  };

  return {
    ...toRefs(state),
    openBulkCreateModal,
    closeBulkCreateModal,
    openReceptionShiftModal,
    closeReceptionShiftModal,
    openSettingDisplayItemModal,
    closeSettingDisplayItemModal,
    openDisplayConditionSettingModal,
    closeDisplayConditionSettingModal,
    openShiftRegisterModal,
    closeShiftRegisterModal,
    openShiftBulkEditModal,
    closeShiftBulkEditModal,
    openYearSummaryModal,
    closeYearSummaryModal,
    openUpdateMonthlySummaryModal,
    closeUpdateMonthlySummaryModal,
  };
};

export const ModalDisplayStateKey: InjectionKey<ModalDisplay> = Symbol('ModalDisplay');
