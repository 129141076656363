import { BudgetGroup } from 'src/models/budgetGroup'
import { TimeInteger } from 'src/models/common'
import { PlanBoardIndexResult } from 'src/models/planBoard'
import { TimetableMaster } from 'src/models/timetableMaster'
import { WorkplaceExtension } from 'src/models/workplaceExtension'

/**
 * 各工程の概要情報
 * 表で言うところの左側の情報
 */
export type TimelineHeader = {
  name: string
  backgroundColor: string
  dispOrder: number
  resultQuantity: number | null // 実績作業量
  scheduledQuantity: number | null // 予定作業量
  startTime: string // 開始時間
  endTime: string // 終了時間
  totalProductivity: number | null // （累計）生産性
  targetProductivity: number | null // 標準（生産性）
  resultManHours: number | null // 投下人時
  scheduledManHours: number | null // 必要人時
}

/**
 * 1 ブロックの人時情報
 */
export type TimelineHeadcountData = {
  headcount: number
  backgroundColor: string
}

/**
 * 1 時間単位の headcount 情報の配列が入った配列
 */
export type TimelineHeadcountHourBlocks = Array<TimelineHeadcountData[]>

/**
 * 1 時間ごとの表示に必要な情報
 */
export type TimelineHourBlock = {
  totalHeadcount: number // 合計人数: 表示に利用しないが他の情報の計算に利用
  quantity: number // 経過数量: この時間までの合計数量
  completionRatio: number // 完了率: 目標数量に対して何 % 達成しているかの表示に利用
  headcountList: TimelineHeadcountData[] // 人時情報配列: 人時と背景色情報を持つ配列
}

export type ProgressHistory = {
  totalQuantity: number
  productivity: number
  isHiddenInCell: boolean
}

/**
 * 工程情報
 * 表で言うところの 1 行分の情報を持つ
 */
export type TimelineTimetable = {
  id: number;
  masterId: number;
  header: TimelineHeader
  progressHistories: ProgressHistory[]
  boardHourBlocks: TimelineHourBlock[]
  historiesStyle: Record<string, string>
}

export type DayHeadcount = {
  total: number
  targetTimeRange: number
}

export type TimelineState = {
  workplaceId: string
  workplaceExtension: WorkplaceExtension
  budgetGroup: BudgetGroup | null
  budgetGroups: BudgetGroup[]
  baseDate: Date | null
  timetableMasters: TimetableMaster[]
  timetables: TimelineTimetable[]
  displayTimes: string[] // 表示時間一覧 例) ['8:00', '9:00', ..., '20:00']
  displayStartHour: number // 表示開始時間: 初期スクロール位置
  displayHourPeriod: number // 表示したい時間数 例) 48 の場合は 0:00~47:00 の時間を表示する
  totalHeadcountHourBlocks: TimelineHeadcountHourBlocks // 単位時間ごとの合計人時
  blockLengthPerHour: number // 1 時間内の単位時間数 例) 単位時間が 15 分の場合 4
  lastSaveTimestamp: string // 最後に保存された時刻
  lastSavedBy: string // 最後に保存したユーザー名
  targetStartTime: TimeInteger // サマリ欄の計算で利用する開始時刻
  targetEndTime: TimeInteger // サマリ欄の計算で利用する終了時刻
}

export type TimelineOverviewState = {
  headcountTotal: {
    shift: DayHeadcount
    scheduled: DayHeadcount
    result: DayHeadcount
  }
  shiftRegular: DayHeadcount
  shiftItems: Array<{
    name: string
    headcount: DayHeadcount
  }>
  shiftItemsTotal: DayHeadcount
  checkList: Array<{
    name: string
    key: string
    icon: string
    color: string
    total: number
    targetTimeRange: number
  }>
  memo: string
}

export type TimelineBreakTime = {
  startTime: TimeInteger
  endTime: TimeInteger
}

export type TimelineStaffForAllocation = {
  startTime: TimeInteger
  endTime: TimeInteger
  breakTimes: TimelineBreakTime[]
  skills: number[]
  timeBlocks: Array<{
    isAllocated: boolean
  }>
}

export const TIMELINE_COLUMN_WIDTH = 100 // 時間列の 1 列分の幅
