



























import { defineComponent, type PropType } from '@vue/composition-api'
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'
import CancelButton from './CancelButton.vue'

export default defineComponent({
  components: {
    CancelButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    shiftPatterns: {
      type: Array as PropType<ShiftPattern[]>,
      required: true,
    },
  },
  setup(_, context) {
    const handleSelect = (shiftPattern: ShiftPattern) => {
      context.emit('select', shiftPattern)
      context.emit('close')
    }

    return {
      handleSelect,
    }
  },
})
