import { ref, computed, type Ref, type ComputedRef } from '@vue/composition-api';
import { startOfMonth, eachDayOfInterval, differenceInDays } from 'date-fns';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type MacroOperationMaster } from 'src/models/macroOperationMaster';
import { type StaffLabel } from 'src/models/staffLabel';
import { createInjection } from 'src/util/createInjection';
import { type DateRange } from '../types/DateRange';
import { type ShiftStatus } from '../types/ShiftStatus';
import { type WeekDay } from '../types/WeekDay';

type InjectionValue = {
  dateRange: Ref<DateRange>;
  weekDays: Ref<WeekDay[]>;
  budgetGroup: Ref<BudgetGroup | null>;
  shiftStatus: Ref<ShiftStatus>;
  macroOperationMasters: Ref<MacroOperationMaster[]>;
  staffLabels: Ref<StaffLabel[]>;
  isKeyPlayer: Ref<boolean>;
  isForkman: Ref<boolean>;
  hasCustomSkill1: Ref<boolean>;
  hasCustomSkill2: Ref<boolean>;
  hasCustomSkill3: Ref<boolean>;
  dates: ComputedRef<Date[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useSearchConditions');

function toDates({ dateRange, weekDays }: { dateRange: DateRange; weekDays: WeekDay[] }): Date[] {
  if (differenceInDays(dateRange.endDate, dateRange.startDate) < 0) {
    return [];
  }

  const castWeekDays: number[] = weekDays; // 後続のincludesで型エラーになるため、number[]にキャストする
  return eachDayOfInterval({ start: dateRange.startDate, end: dateRange.endDate })
    .filter((v) => castWeekDays.length === 0 || castWeekDays.includes(v.getDay()))
    .slice(0, 31); // 最大31日分
}

export function useSearchConditionsProvider(): void {
  const dateRange = ref<DateRange>({ startDate: startOfMonth(new Date()), endDate: new Date() });
  const weekDays = ref<WeekDay[]>([]);
  const budgetGroup = ref<BudgetGroup | null>(null);
  const shiftStatus = ref<ShiftStatus>('actual');
  const macroOperationMasters = ref<MacroOperationMaster[]>([]);
  const staffLabels = ref<StaffLabel[]>([]);
  const isKeyPlayer = ref(false);
  const isForkman = ref(false);
  const hasCustomSkill1 = ref(false);
  const hasCustomSkill2 = ref(false);
  const hasCustomSkill3 = ref(false);
  const dates = computed(() => toDates({ dateRange: dateRange.value, weekDays: weekDays.value }));

  provide({
    dateRange,
    weekDays,
    budgetGroup,
    shiftStatus,
    macroOperationMasters,
    staffLabels,
    isKeyPlayer,
    isForkman,
    hasCustomSkill1,
    hasCustomSkill2,
    hasCustomSkill3,
    dates,
  });
}

export function useSearchConditions(): InjectionValue {
  return inject();
}
