import { TimeInteger } from 'src/models/common';
import { AttendanceSupplement, AttendanceType } from 'src/consts';
import { StaffShiftResponse } from 'src/models/api/staffShiftResponse';
import { parseYmdDate } from 'src/util/datetime';
import { toNumber, toNumberOrNull } from 'src/util/numbers';
import { convertStaffResponse, Staff } from 'src/models/staff';

export interface StaffShift {
  id: number;
  staff_id: number;
  staff?: Staff;
  dt: Date;
  attendance_type: AttendanceType; // TODO 削除予定
  scheduled_attendance_type: AttendanceType;
  actual_attendance_type: AttendanceType;
  attendance_supplement: AttendanceSupplement | null;
  hourly_cost: number;
  is_change_at_night: boolean;
  is_beginner: boolean;
  scheduled_work_start_time: TimeInteger | null;
  scheduled_work_end_time: TimeInteger | null;
  scheduled_break1_start_time: TimeInteger | null;
  scheduled_break1_end_time: TimeInteger | null;
  scheduled_break2_start_time: TimeInteger | null;
  scheduled_break2_end_time: TimeInteger | null;
  scheduled_break_time: TimeInteger | null;
  possible_over_time: TimeInteger | null;
  actual_work_start_time: TimeInteger | null;
  actual_work_end_time: TimeInteger | null;
  actual_break_time: TimeInteger | null;
  overtime_extra_rate: number;
  midnight_extra_rate: number;
  holiday_extra_rate: number;
  over_sixty_hours_extra_rate: number;
  standard_working_hours: TimeInteger | null;
  midnight_time_start: TimeInteger | null;
  midnight_time_end: TimeInteger | null;
  scheduled_hours_normal: number;
  scheduled_hours_overtime: number;
  scheduled_hours_midnight: number;
  scheduled_hours_holiday: number;
  scheduled_hours_total: number;
  scheduled_normal_cost_sum: number;
  scheduled_overtime_cost_sum: number;
  scheduled_midnight_cost_sum: number;
  scheduled_holiday_cost_sum: number;
  scheduled_all_cost_sum: number;
  actual_hours_normal: number | null;
  actual_hours_overtime: number | null;
  actual_hours_midnight: number | null;
  actual_hours_holiday: number | null;
  actual_hours_total: number | null;
  actual_normal_cost_sum: number | null;
  actual_overtime_cost_sum: number | null;
  actual_midnight_cost_sum: number | null;
  actual_holiday_cost_sum: number | null;
  actual_all_cost_sum: number | null;
  memo: string | null;
}

export function convertStaffShiftResponse(response: StaffShiftResponse): StaffShift {
  const { staff, ...rest } = response;
  return {
    ...rest,
    ...(staff ? { staff: convertStaffResponse(staff) } : null),
    dt: parseYmdDate(response.dt),
    overtime_extra_rate: toNumber(response.overtime_extra_rate),
    midnight_extra_rate: toNumber(response.midnight_extra_rate),
    holiday_extra_rate: toNumber(response.holiday_extra_rate),
    over_sixty_hours_extra_rate: toNumber(response.over_sixty_hours_extra_rate),
    scheduled_hours_normal: toNumber(response.scheduled_hours_normal),
    scheduled_hours_overtime: toNumber(response.scheduled_hours_overtime),
    scheduled_hours_midnight: toNumber(response.scheduled_hours_midnight),
    scheduled_hours_holiday: toNumber(response.scheduled_hours_holiday),
    scheduled_hours_total: toNumber(response.scheduled_hours_total),
    actual_hours_normal: toNumberOrNull(response.actual_hours_normal),
    actual_hours_overtime: toNumberOrNull(response.actual_hours_overtime),
    actual_hours_midnight: toNumberOrNull(response.actual_hours_midnight),
    actual_hours_holiday: toNumberOrNull(response.actual_hours_holiday),
    actual_hours_total: toNumberOrNull(response.actual_hours_total),
  };
}
