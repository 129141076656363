













import { defineComponent } from '@vue/composition-api'
import { useProgressHeader } from '../../composables/useProgressHeader'
import { useUpdateActualCompletionTime } from '../../composables/useUpdateActualCompletionTime'
import { CardPanel } from '../CardPanel'
import { EditableTime } from '../EditableTime'

export default defineComponent({
  components: {
    CardPanel,
    EditableTime,
  },
  setup() {
    const { progressHeader } = useProgressHeader()
    const { updateActualCompletionTime } = useUpdateActualCompletionTime()

    return {
      progressHeader,
      updateActualCompletionTime,
    }
  },
})
