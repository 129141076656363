














import { defineComponent } from '@vue/composition-api'
import { useBackgroundProcessStatuses } from '../../composables/useBackgroundProcessStatuses'
import { useDownloadCsv } from '../../composables/useDownloadCsv'
import { BackgroundProcessStatusList, BackgroundProcessStatusListItem } from '../BackgroundProcessStatusList'

export default defineComponent({
  components: {
    BackgroundProcessStatusList,
    BackgroundProcessStatusListItem,
  },
  setup() {
    const { backgroundProcessStatuses } = useBackgroundProcessStatuses()
    const { downloadCsv } = useDownloadCsv()

    return {
      backgroundProcessStatuses,
      downloadCsv,
    }
  },
})
