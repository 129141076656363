// TODO ディレクトリ名を DailyBoardPrep に変更予定
import Vue from 'vue';
import Draggable from 'vuedraggable';
import { defineComponent, SetupContext, reactive, onMounted, ref, computed, nextTick } from '@vue/composition-api';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import collectiveStaffShiftApi from 'src/apis/collectiveStaffShift';
import planBoardApi from 'src/apis/planBoard';
import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { isExist } from 'src/util/isExist';
import {
  formatDate,
  packToTimeInteger,
  secondsToTimeInteger,
  timeIntegerToSeconds,
  timeStrToTimeInteger,
  unpackTimeInteger,
  unpackTimeIntegerToStringFormat,
} from 'src/util/datetime';
import { PlanBoardIndexResult } from 'src/models/planBoard';
import HeadcountMoveModal from './HeadcountMoveModal/index.vue';
import PlusMinusValue from './PlusMinusValue/index.vue';
import TimeRangeInput from 'src/components/Workplace/TimeRangeInput/index.vue';
import { headcountMoveModalHooks } from './HeadcountMoveModal/script';
import {
  MAX_STAFF_SKILL_COUNT,
  PlanBoardDragState,
  PlanBoardHeadcountData,
  PlanBoardHeadcountHourBlocks,
  PlanBoardOverviewState,
  PlanBoardStaffForAllocation,
  PlanBoardState,
  PlanBoardTimetable,
  PLAN_BOARD_BREAK_TIME_MASTER_ID,
  PLAN_BOARD_SURPLUS_MASTER_ID,
  TIMELINE_COLUMN_WIDTH,
} from './types';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import {
  createCheckListFromApiResponse,
  createHeadcountHourBlocksFromApiResponse,
  createHeaderFromApiResponse,
  createInitialBoadHourBlocks,
  createShiftInputFromApiResponse,
  createShiftInputRegularFromApiResponse,
  createStaffForAllocationFromCollectiveStaffShifts,
  createStaffForAllocationFromStaffShifts,
  createTimetablesFromApiResponse,
  createTotalHeadcountHourBlocksFromApiResponse,
  createHeadcountTotal,
  forEachBreakTimeBlockTime,
  forEachWorkingBlockTime,
  getBlockLengthPerHour,
  getHeadcountBackgroundColor,
  isCollectiveStaffInTimeRange,
  updateHeaderAndBoardHourBlocks,
  validateHeader,
  formatExcessOrDeficiency,
} from './planBoardHelper';
import UnFocusOutlineButton from 'src/components/UnFocusOutlineButton.vue';
import { dailyBoardPageOptions } from 'src/consts';

const roundValue = (_value?: number): number | undefined => {
  if (!isExist(_value) || _value.toString().length === 0) {
    return _value;
  }
  if (isNaN(Number(_value))) {
    return _value;
  }
  return Math.round(_value);
};

const setupState = (context: SetupContext): PlanBoardState => {
  const root = context.root as Vue;

  const displayHourPeriod = 48; // 表示したい時間数: 現状外部からの指定はないので 48 固定
  const state: PlanBoardState = reactive({
    ...wrappedMapGetters(root.$store, 'workplace', ['workplaceExtension']),
    budgetGroup: null,
    budgetGroups: [],
    baseDate: new Date(),
    workplaceId: root.$route.params.workplaceId,
    timetables: [],
    surplusTimetable: null,
    displayTimes: new Array(displayHourPeriod).fill(0).map((startHour, i) => `${startHour + i}:00`),
    displayStartHour: computed(() => state.workplaceExtension?.timetable_start_hour ?? 8),
    displayHourPeriod,
    totalHeadcountHourBlocks: [],
    blockLengthPerHour: 4,
    apiResponse: null,
    lastSaveTimestamp: '',
    lastSavedBy: '',
    isChanged: false,
    targetStartTime: 0,
    targetEndTime: packToTimeInteger(24, 0, 0),
  });
  state.targetStartTime = packToTimeInteger(state.workplaceExtension?.timetable_start_hour ?? 8, 0, 0);
  return state;
};

const setupDragState = (): PlanBoardDragState => {
  const dragState: PlanBoardDragState = reactive({
    targetTimetableIndex: 0,
    draggedTimetableIndex: 0,
    draggedHeadcountIndex: 0,
  });
  return dragState;
};

const setupOverviewState = (planBoardState: PlanBoardState): PlanBoardOverviewState => {
  const overviewState: PlanBoardOverviewState = reactive({
    headcountTotal: createHeadcountTotal(planBoardState),
    shiftInputRegular: { total: 0, targetTimeRange: 0 },
    shiftInput: [],
    shiftInputTotal: { total: 0, targetTimeRange: 0 },
    checkList: [
      { name: 'キープレイヤー', key: 'is_key_player', icon: 'fa-star', color: '#ffc000', total: 0, targetTimeRange: 0 },
      { name: 'フォークマン', key: 'is_forkman', icon: 'fa-forklift', color: '#b1c796', total: 0, targetTimeRange: 0 },
      { name: '-', key: 'has_custom_skill1', icon: 'fa-flag', color: '#ed7d31', total: 0, targetTimeRange: 0 },
      { name: '-', key: 'has_custom_skill2', icon: 'fa-flag', color: '#8497b0', total: 0, targetTimeRange: 0 },
      { name: '-', key: 'has_custom_skill3', icon: 'fa-flag', color: '#222a35', total: 0, targetTimeRange: 0 },
    ],
    memo: '',
  });
  return overviewState;
};

export default defineComponent({
  components: {
    Draggable,
    HeadcountMoveModal,
    PlusMinusValue,
    TimeRangeInput,
    UnFocusOutlineButton,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, '当日ボード');
    const state = setupState(context);
    const dragState = setupDragState();
    const overviewState = setupOverviewState(state);
    const planBoardTimelineStyle = {
      gridTemplateColumns: `repeat(${state.displayHourPeriod}, ${TIMELINE_COLUMN_WIDTH}px)`,
      display: 'grid',
    };
    const planBoardTimelineRef = ref<HTMLDivElement>();
    const planBoardHeaderTableRef = ref<HTMLDivElement>();
    const planBoardTimelineTableRef = ref<HTMLDivElement>();
    const routeName = root.$route.name;

    onMounted(async () => {
      await ensureUserAndMasters(context);
      state.budgetGroups = await budgetGroupApi.index(state.workplaceId);
      state.budgetGroup = state.budgetGroups[0];
    });

    /**
     * 割り付け用の準備を行い、割り付けに利用する情報を返す
     */
    const prepareForAllocate = (apiResponse: PlanBoardIndexResult | null) => {
      // 割り付け可能かを検証する
      const isInvalid = !validateForAllocation(apiResponse);
      if (isInvalid) {
        return;
      }

      // 各工程の人時ブロックを初期化
      state.timetables.forEach((timetable) => {
        timetable.boardHourBlocks = createInitialBoadHourBlocks(state.displayHourPeriod, state.blockLengthPerHour);
      });

      // 休憩割り振り用に予め休憩 Timetable を保持しておく
      const breakTimeTimetable = state.timetables.find(
        (timetable) => timetable.masterId === PLAN_BOARD_BREAK_TIME_MASTER_ID,
      );
      if (!isExist(breakTimeTimetable)) {
        return;
      }

      // 余剰割り振り用に予め応援 Timetable を保持しておく
      const surplusTimeTimetable = state.timetables.find(
        (timetable) => timetable.masterId === PLAN_BOARD_SURPLUS_MASTER_ID,
      );
      if (!isExist(surplusTimeTimetable)) {
        return;
      }

      const timeBlockLength = state.displayHourPeriod * state.blockLengthPerHour;
      const staffsFromStaffShifts = createStaffForAllocationFromStaffShifts(apiResponse.staff_shifts, timeBlockLength);
      const staffsFromCollectiveStaffShifts = createStaffForAllocationFromCollectiveStaffShifts(
        apiResponse.collective_staff_shifts,
        timeBlockLength,
      );
      const staffs = staffsFromStaffShifts.concat(staffsFromCollectiveStaffShifts);

      return {
        breakTimeTimetable,
        surplusTimeTimetable,
        staffs,
      };
    };

    /**
     * 「スピード」から割り付けを行う
     */
    const allocateStaffs = (): void => {
      const dataForAllocate = prepareForAllocate(state.apiResponse);
      if (!isExist(dataForAllocate)) {
        return;
      }

      const { breakTimeTimetable, surplusTimeTimetable, staffs } = dataForAllocate;

      for (let hour = 0; hour < state.displayHourPeriod; hour++) {
        for (let blockIndex = 0; blockIndex < state.blockLengthPerHour; blockIndex++) {
          const targetTime = packToTimeInteger(hour, Math.round((60 / state.blockLengthPerHour) * blockIndex), 0);

          // 現在の時間帯で有効なスタッフを抽出
          const availableStaffs = staffs.filter((staff) => staff.startTime <= targetTime && targetTime < staff.endTime);

          // 現在の時間帯で有効な工程を抽出 { [masterId]: Timetable } な形式に変換
          const availableTimetableMap = state.timetables
            .filter((timetable) => {
              return (
                timeStrToTimeInteger(timetable.header.startTime) <= targetTime &&
                targetTime < timeStrToTimeInteger(timetable.header.endTime) &&
                timetable.masterId !== PLAN_BOARD_BREAK_TIME_MASTER_ID
              );
            })
            .reduce((map, timetable) => {
              return {
                ...map,
                [timetable.masterId]: timetable,
              };
            }, {} as Record<number, PlanBoardTimetable>);

          // 休憩の場合は休憩に割り付ける
          availableStaffs.forEach((staff) => {
            if (staff.breakTimes.some(({ startTime, endTime }) => startTime <= targetTime && targetTime < endTime)) {
              allocateStaffToTimetable(staff, breakTimeTimetable, hour, blockIndex);
            }
          });

          // 各スタッフの担当工程の優先度順に割り付けを行う
          for (let i = 0; i < MAX_STAFF_SKILL_COUNT; i++) {
            availableStaffs.forEach((staff) => {
              allocateStaffToTimetable(staff, availableTimetableMap[staff.skills[i]], hour, blockIndex);
            });
          }

          // 余ったスタッフは余剰へ割り付ける
          availableStaffs.forEach((staff) => {
            allocateStaffToTimetable(staff, surplusTimeTimetable, hour, blockIndex);
          });

          // 合計値を更新
          state.totalHeadcountHourBlocks[hour][blockIndex].headcount =
            availableStaffs.length - breakTimeTimetable.boardHourBlocks[hour].headcountList[blockIndex].headcount;
          state.totalHeadcountHourBlocks[hour][blockIndex].backgroundColor = getHeadcountBackgroundColor(
            state.totalHeadcountHourBlocks[hour][blockIndex].headcount,
          );
        }
      }

      // 割り付け後の値で過不足や進捗率などの数値を再計算する
      state.timetables.forEach((_, i) => updateTimetable(i));
    };

    /**
     * 「バランス」から割り付けを行う
     */
    const allocateStaffsEvenly = (): void => {
      const dataForAllocate = prepareForAllocate(state.apiResponse);
      if (!isExist(dataForAllocate)) {
        return;
      }

      const { breakTimeTimetable, surplusTimeTimetable, staffs } = dataForAllocate;

      // 休憩時間へ割り付ける
      staffs.forEach((staff, i) => {
        forEachBreakTimeBlockTime(staff, state.blockLengthPerHour, (hour, blockIndex) => {
          allocateStaffToTimetable(staff, breakTimeTimetable, hour, blockIndex);
        });
      });

      const timetableMap = state.timetables.reduce((map, timetable) => {
        return {
          ...map,
          [timetable.masterId]: timetable,
        };
      }, {} as Record<number, PlanBoardTimetable>);

      // 担当工程の優先度順に割り付けを行う
      for (let i = 0; i < MAX_STAFF_SKILL_COUNT; i++) {
        staffs.forEach((staff) => {
          forEachWorkingBlockTime(staff, state.blockLengthPerHour, (hour, blockIndex) => {
            const timetable = timetableMap[staff.skills[i]];
            if (!isExist(timetable)) {
              return;
            }

            const targetTime = packToTimeInteger(hour, Math.round((60 / state.blockLengthPerHour) * blockIndex), 0);
            if (targetTime < timeStrToTimeInteger(timetable.header.startTime)) {
              return;
            }
            if (timeStrToTimeInteger(timetable.header.endTime) <= targetTime) {
              return;
            }

            allocateStaffToTimetable(staff, timetable, hour, blockIndex);
          });
        });
      }

      for (let hour = 0; hour < state.displayHourPeriod; hour++) {
        for (let blockIndex = 0; blockIndex < state.blockLengthPerHour; blockIndex++) {
          const targetTime = packToTimeInteger(hour, Math.round((60 / state.blockLengthPerHour) * blockIndex), 0);

          // 現在の時間帯で有効なスタッフを抽出
          const availableStaffs = staffs.filter((staff) => staff.startTime <= targetTime && targetTime < staff.endTime);

          // 余ったスタッフは余剰へ割り付ける
          availableStaffs.forEach((staff) => {
            allocateStaffToTimetable(staff, surplusTimeTimetable, hour, blockIndex);
          });

          // 合計値を更新
          state.totalHeadcountHourBlocks[hour][blockIndex].headcount =
            availableStaffs.length - breakTimeTimetable.boardHourBlocks[hour].headcountList[blockIndex].headcount;
          state.totalHeadcountHourBlocks[hour][blockIndex].backgroundColor = getHeadcountBackgroundColor(
            state.totalHeadcountHourBlocks[hour][blockIndex].headcount,
          );
        }
      }

      // 割り付け後の値で過不足や進捗率などの数値を再計算する
      state.timetables.forEach((_, i) => updateTimetable(i));
    };

    /**
     * スタッフを工程に割り付ける
     */
    const allocateStaffToTimetable = (
      staff: PlanBoardStaffForAllocation,
      targetTimetable: PlanBoardTimetable | null,
      hour: number,
      blockIndex: number,
    ): void => {
      const timeBlockIndex = getHeadcountIndex(hour, blockIndex);

      // 対象の工程が存在しない場合は割り付けない
      if (!isExist(targetTimetable)) {
        return;
      }
      // 既に割り付けられてる場合は割り付けない
      if (staff.timeBlocks[timeBlockIndex].isAllocated) {
        return;
      }
      // 配置限界に達している場合は割り付けない
      if (
        targetTimetable.boardHourBlocks[hour].headcountList[blockIndex].headcount >=
        targetTimetable.header.maxAllocations!
      ) {
        return;
      }
      // 必要人時に達している場合は割り付けない
      if (
        (targetTimetable.header.requiredHeadcount ?? 0) <=
        targetTimetable.boardHourBlocks.reduce(
          (sum, boardHourBlock) =>
            sum + boardHourBlock.headcountList.reduce((sum, headcount) => sum + headcount.headcount, 0),
          0,
        ) /
          state.blockLengthPerHour
      ) {
        return;
      }

      // 割り付けを行う
      staff.timeBlocks[timeBlockIndex].isAllocated = true;
      targetTimetable.boardHourBlocks[hour].headcountList[blockIndex].headcount++;
    };

    /**
     * 割り付け実行前の検証を行う
     */
    const validateForAllocation = <T = PlanBoardIndexResult | null>(apiResponse: T): apiResponse is NonNullable<T> => {
      if (!isExist(apiResponse)) {
        notifyError1(root, '割り付け前に「表示」ボタンを押して、情報の読み込みを行ってください');
        return false;
      }
      const isInvalid = !validateTimetables();
      if (isInvalid) {
        notifyError1(root, '割り付け前に正しい工程情報を入力してください');
        return false;
      }

      return true;
    };

    /**
     * DB への保存を行う
     */
    const saveToDB = async (): Promise<void> => {
      if (!state.baseDate) {
        return;
      }
      if (!state.budgetGroup) {
        return;
      }

      const isInvalid = !validateTimetables();
      if (isInvalid) {
        notifyError1(root, '保存前に正しい工程情報を入力してください');
        return;
      }

      try {
        await planBoardApi.planBoardBlockBulkUpdate({
          workplace_id: state.workplaceId,
          budget_group_id: state.budgetGroup.id,
          dt: state.baseDate,
          timetable_headers: state.timetables.map((timetable) => {
            return {
              id: timetable.id,
              start_time: timeStrToTimeInteger(timetable.header.startTime),
              end_time: timeStrToTimeInteger(timetable.header.endTime),
              quantity: timetable.header.targetQuantity,
              first_half_target_ratio: 0,
              productivity: timetable.header.productivity,
              max_allocations: timetable.header.maxAllocations!,
              plan_board_blocks: timetable.boardHourBlocks.flatMap((hourBlock, hour) => {
                return hourBlock.headcountList.map((headcount, blockIndex) => {
                  const blockMinutes = Math.round(60 / state.blockLengthPerHour);
                  const startTime = packToTimeInteger(hour, blockMinutes * blockIndex, 0);
                  return {
                    start_time: startTime,
                    end_time: secondsToTimeInteger(timeIntegerToSeconds(startTime) + blockMinutes * 60),
                    headcount: headcount.headcount,
                  };
                });
              }),
            };
          }),
        });
        notifySuccess1(root, '当日計画を保存しました');
      } catch (err: any) {
        notifyError1(root, '当日計画の保存に失敗しました', { err });
      }
    };

    /**
     * 各工程の検証を行う
     */
    const validateTimetables = (): boolean => {
      state.timetables.forEach(({ header, masterId }) => {
        if (masterId < 0) {
          return;
        }
        header.validation = validateHeader(header);
      });
      return state.timetables.every(({ header }) => {
        return Object.values(header.validation).every((value) => value);
      });
    };

    /**
     * API の値で表をリセットする
     */
    const refreshPlanBoard = async (): Promise<void> => {
      if (!state.baseDate) {
        return;
      }
      if (!state.budgetGroup) {
        return;
      }

      let planBoardResponse: PlanBoardIndexResult;
      try {
        // planBoardResponse = await fetchPlanBoardMock(state.baseDate, state.budgetGroup.id)
        planBoardResponse = await planBoardApi.planBoardIndex({
          workplace_id: state.workplaceId,
          budget_group_id: state.budgetGroup.id,
          dt: state.baseDate,
          use_in_prep_phase: true,
          use_in_review_phase: null,
        });
      } catch (err: any) {
        notifyError1(root, '表示情報の取得に失敗しました', { err });
        return;
      }

      state.apiResponse = planBoardResponse;
      if (isExist(planBoardResponse.budget_group_plan_board_misc)) {
        state.lastSaveTimestamp = isExist(planBoardResponse.budget_group_plan_board_misc.last_save_timestamp)
          ? formatDate(planBoardResponse.budget_group_plan_board_misc.last_save_timestamp, 'MM/dd HH:mm')
          : '';
        state.lastSavedBy = planBoardResponse.budget_group_plan_board_misc.last_saved_by?.toString() ?? '';
      }

      state.blockLengthPerHour = getBlockLengthPerHour(planBoardResponse);
      state.timetables = createTimetablesFromApiResponse(
        planBoardResponse,
        state.blockLengthPerHour,
        state.displayHourPeriod,
        state.workplaceExtension,
      );
      state.surplusTimetable =
        state.timetables.find(({ masterId }) => masterId === PLAN_BOARD_SURPLUS_MASTER_ID) ?? null;
      state.totalHeadcountHourBlocks = createTotalHeadcountHourBlocksFromApiResponse(
        state.timetables,
        state.blockLengthPerHour,
        state.displayHourPeriod,
      );

      state.timetables.forEach((_, i) => updateTimetable(i));

      updateOverViewState(planBoardResponse);

      nextTick(() => {
        if (isExist(planBoardTimelineRef.value)) {
          const [startHour] = unpackTimeInteger(state.targetStartTime);
          planBoardTimelineRef.value.scrollLeft = startHour * TIMELINE_COLUMN_WIDTH;
        }
      });
    };

    /**
     * サマリ欄の情報を更新する
     */
    const updateOverViewState = (planBoardResponse: PlanBoardIndexResult): void => {
      overviewState.shiftInputRegular = createShiftInputRegularFromApiResponse(planBoardResponse.staff_shifts, state);
      overviewState.shiftInput = createShiftInputFromApiResponse(planBoardResponse.collective_staff_shifts, state);
      updateShiftInput();
      overviewState.headcountTotal = createHeadcountTotal(state);
      overviewState.checkList = createCheckListFromApiResponse(
        overviewState.checkList,
        planBoardResponse.staff_shifts,
        state,
        state.workplaceExtension,
      );
      overviewState.memo = planBoardResponse.budget_group_plan_board_misc?.memo ?? '';
    };

    /**
     * 工程情報を更新する
     * 目標数量や生産性を変更した場合など、再計算が必要なタイミングで明示的に叩く必要がある
     */
    const updateTimetable = (targetTimetableIndex: number): void => {
      const targetTimetable = state.timetables[targetTimetableIndex];
      if (!isExist(targetTimetable)) {
        return;
      }

      const { header: targetHeader, boardHourBlocks: targetBoardHourBlocks } = updateHeaderAndBoardHourBlocks(
        targetTimetable.header,
        targetTimetable.boardHourBlocks.map((boardHourBlock) => boardHourBlock.headcountList),
        state.blockLengthPerHour,
        state.surplusTimetable,
      );
      targetTimetable.header = targetHeader;
      targetTimetable.boardHourBlocks = targetBoardHourBlocks;
      targetTimetable.isChanged = checkIsChanged(targetTimetableIndex);
      state.isChanged = state.timetables.some((timetable) => timetable.isChanged);

      // 要員合計を更新する
      overviewState.headcountTotal = createHeadcountTotal(state);
    };

    /**
     * シフト入力情報を更新する
     */
    const updateShiftInput = async (shiftIndex: number = -1): Promise<void> => {
      if (!state.baseDate) {
        return;
      }
      if (!state.budgetGroup) {
        return;
      }
      if (!isExist(state.apiResponse)) {
        return;
      }

      let isChanged = false;
      const shiftInputTotal = {
        total: overviewState.shiftInputRegular.total,
        targetTimeRange: overviewState.shiftInputRegular.targetTimeRange,
      };
      const collective_staff_shifts = state.apiResponse.collective_staff_shifts;
      overviewState.shiftInput.forEach((shift, i) => {
        if (isCollectiveStaffInTimeRange(collective_staff_shifts[i].collective_staff, state)) {
          shift.headcount.targetTimeRange = shift.headcount.total;
        }
        shiftInputTotal.targetTimeRange += shift.headcount.targetTimeRange;
        shiftInputTotal.total += shift.headcount.total;
        // API から受け取った情報と比較して更新があったか確認する。
        const originalCollectiveStaffShift = collective_staff_shifts[i];
        isChanged ||= shift.headcount.total !== originalCollectiveStaffShift?.headcount;
      });
      overviewState.shiftInputTotal = shiftInputTotal;

      if (!isChanged) {
        return;
      }
      const collectiveStaffShift = collective_staff_shifts[shiftIndex];
      if (!isExist(collectiveStaffShift)) {
        return;
      }

      try {
        const res = await collectiveStaffShiftApi.upsert({
          workplace_id: state.workplaceId,
          budget_group_id: state.budgetGroup.id,
          dt: state.baseDate,
          collective_staff_id: collectiveStaffShift.collective_staff_id,
          headcount: overviewState.shiftInput[shiftIndex].headcount.total,
        });
        const responsedIndex = state.apiResponse.collective_staff_shifts.findIndex(
          (collective_staff_shift) => collective_staff_shift.collective_staff_id === res.collective_staff_id,
        );
        state.apiResponse.collective_staff_shifts[responsedIndex] = { ...collectiveStaffShift, ...res };
        notifySuccess1(root, 'シフト情報を更新しました');
      } catch (err: any) {
        notifyError1(root, 'シフト情報の更新に失敗しました', { err });
      }
    };

    /**
     * メモの更新
     */
    const updateMemo = async (): Promise<void> => {
      if (!state.baseDate) {
        return;
      }
      if (!state.budgetGroup) {
        return;
      }
      if (!isExist(state.apiResponse)) {
        return;
      }
      if (overviewState.memo === state.apiResponse.budget_group_plan_board_misc?.memo) {
        return;
      }

      try {
        const budget_group_plan_board_misc = await budgetGroupPlanBoardMiscApi.upsert({
          workplace_id: state.workplaceId,
          budget_group_id: state.budgetGroup.id,
          dt: state.baseDate,
          memo: overviewState.memo,
        });
        state.apiResponse.budget_group_plan_board_misc = budget_group_plan_board_misc;
        notifySuccess1(root, 'メモを更新しました');
      } catch (err: any) {
        notifyError1(root, 'メモの更新に失敗しました', { err });
      }
    };

    /**
     * 情報に変更があるかを確認する
     */
    const checkIsChanged = (targetTimetableIndex: number): boolean => {
      const targetTimetable = state.timetables[targetTimetableIndex];
      const _apiTimetableHeader = state.apiResponse?.timetable_headers[targetTimetableIndex];
      if (!isExist(targetTimetable) || !isExist(_apiTimetableHeader)) {
        return false;
      }
      const apiTimetableHeader = createHeaderFromApiResponse(_apiTimetableHeader);
      const apiHeadcountHourBlocks = createHeadcountHourBlocksFromApiResponse(
        _apiTimetableHeader.plan_board_blocks,
        state.blockLengthPerHour,
        state.displayHourPeriod,
      );

      const { startTime, endTime, targetQuantity, productivity, maxAllocations } = targetTimetable.header;

      if (startTime !== apiTimetableHeader.startTime) {
        return true;
      }
      if (endTime !== apiTimetableHeader.endTime) {
        return true;
      }
      if (Number(targetQuantity) !== apiTimetableHeader.targetQuantity) {
        return true;
      }
      if (Number(productivity) !== apiTimetableHeader.productivity) {
        return true;
      }
      if (maxAllocations !== apiTimetableHeader.maxAllocations) {
        return true;
      }
      const isSomeHeadcountChanged = targetTimetable.boardHourBlocks.some((boardHourBlock, i) => {
        return boardHourBlock.headcountList.some((headcountData, j) => {
          return headcountData.headcount !== apiHeadcountHourBlocks[i][j].headcount;
        });
      });
      if (isSomeHeadcountChanged) {
        return true;
      }

      return false;
    };

    /**
     * 人時の移動を行う
     * 移動する人時 (moveHeadcount) と、その時間以降も移動するか (isAlsoMovedAfterThis) の情報を元に処理を行う
     */
    const moveHeadcount = (movedHeadcount: number, isAlsoMovedAfterThis: boolean): void => {
      const draggedTimetable = state.timetables[dragState.draggedTimetableIndex];
      if (!isExist(draggedTimetable)) {
        return;
      }
      const targetTimetable = state.timetables[dragState.targetTimetableIndex];
      if (!isExist(targetTimetable)) {
        return;
      }

      const { draggedHeadcountHourBlocks, targetHeadcountHourBlocks } = updateHeadcountHourBlocks(
        draggedTimetable,
        targetTimetable,
        movedHeadcount,
        isAlsoMovedAfterThis,
      );
      const { header: draggedHeader, boardHourBlocks: draggedBoardHourBlocks } = updateHeaderAndBoardHourBlocks(
        draggedTimetable.header,
        draggedHeadcountHourBlocks,
        state.blockLengthPerHour,
        state.surplusTimetable,
      );
      draggedTimetable.header = draggedHeader;
      draggedTimetable.boardHourBlocks = draggedBoardHourBlocks;
      draggedTimetable.isChanged = checkIsChanged(dragState.draggedTimetableIndex);

      const { header: targetHeader, boardHourBlocks: targetBoardHourBlocks } = updateHeaderAndBoardHourBlocks(
        targetTimetable.header,
        targetHeadcountHourBlocks,
        state.blockLengthPerHour,
        state.surplusTimetable,
      );
      targetTimetable.header = targetHeader;
      targetTimetable.boardHourBlocks = targetBoardHourBlocks;
      targetTimetable.isChanged = checkIsChanged(dragState.targetTimetableIndex);

      state.isChanged = state.timetables.some((timetable) => timetable.isChanged);
      closeHeadcountMoveModal();
    };

    /**
     * 実際に人時の移動を行い、更新後の人時ブロック情報をそれぞれ返す
     */
    const updateHeadcountHourBlocks = (
      { boardHourBlocks: draggedBoardHourBlocks }: PlanBoardTimetable,
      { boardHourBlocks: targetBoardHourBlocks, header: { maxAllocations } }: PlanBoardTimetable,
      _movedHeadcount: number,
      isAlsoMovedAfterThis: boolean,
    ) => {
      const draggedHeadcountHourBlocks: PlanBoardHeadcountHourBlocks = [];
      const targetHeadcountHourBlocks: PlanBoardHeadcountHourBlocks = [];
      draggedBoardHourBlocks.forEach((boadHourBlock, i) => {
        const draggedHeadcountList: PlanBoardHeadcountData[] = [];
        const targetHeadcountList: PlanBoardHeadcountData[] = [];
        boadHourBlock.headcountList.forEach((draggedHeadcountData, j) => {
          const targetHeadcountData = targetBoardHourBlocks[i].headcountList[j];
          const headcountIndex = getHeadcountIndex(i, j);
          if (headcountIndex < dragState.draggedHeadcountIndex) {
            draggedHeadcountList.push({ ...draggedHeadcountData });
            targetHeadcountList.push({ ...targetHeadcountData });
            return;
          }
          if (!isAlsoMovedAfterThis && headcountIndex !== dragState.draggedHeadcountIndex) {
            draggedHeadcountList.push({ ...draggedHeadcountData });
            targetHeadcountList.push({ ...targetHeadcountData });
            return;
          }
          const movedHeadcount = Math.min(
            draggedHeadcountData.headcount,
            _movedHeadcount,
            Math.max(0, (maxAllocations ?? Number.MAX_SAFE_INTEGER) - targetHeadcountData.headcount),
          );
          const nextDraggedHeadcount = draggedHeadcountData.headcount - movedHeadcount;
          const nextTargetHeadcount = targetHeadcountData.headcount + movedHeadcount;
          draggedHeadcountList.push({
            ...draggedHeadcountData,
            headcount: nextDraggedHeadcount,
            backgroundColor: getHeadcountBackgroundColor(nextDraggedHeadcount),
          });
          targetHeadcountList.push({
            ...targetHeadcountData,
            headcount: nextTargetHeadcount,
            backgroundColor: getHeadcountBackgroundColor(nextTargetHeadcount),
          });
        });
        draggedHeadcountHourBlocks.push(draggedHeadcountList);
        targetHeadcountHourBlocks.push(targetHeadcountList);
      });
      return { draggedHeadcountHourBlocks, targetHeadcountHourBlocks };
    };

    const getHeadcountIndex = (hourIndex: number, blockIndex: number): number => {
      return hourIndex * state.blockLengthPerHour + blockIndex;
    };

    const getHeadcount = (timetableIndex: number, headcountIndex: number): number => {
      const i = Math.floor(headcountIndex / state.blockLengthPerHour);
      const j = Math.floor(headcountIndex % state.blockLengthPerHour);
      return state.timetables[timetableIndex].boardHourBlocks[i].headcountList[j].headcount;
    };

    const {
      state: headcountMoveModalState,
      openHeadcountMoveModal,
      closeHeadcountMoveModal,
    } = headcountMoveModalHooks({ moveHeadcount });

    const onDrop = (targetTimetableIndex: number): void => {
      if (targetTimetableIndex === dragState.draggedTimetableIndex) {
        return;
      }
      dragState.targetTimetableIndex = targetTimetableIndex;
      openHeadcountMoveModal(getHeadcount(dragState.draggedTimetableIndex, dragState.draggedHeadcountIndex));
    };

    const onDragstart = (timetableIndex: number, headcountIndex: number): void => {
      dragState.draggedTimetableIndex = timetableIndex;
      dragState.draggedHeadcountIndex = headcountIndex;
    };

    const onPlanBoardHeaderTableScroll = (v: MouseEvent & { target: HTMLDivElement }): void => {
      if (!isExist(planBoardTimelineTableRef.value)) {
        return;
      }
      planBoardTimelineTableRef.value.scrollTop = v.target.scrollTop;
    };

    const onPlanBoardTimelineTableScroll = (v: MouseEvent & { target: HTMLDivElement }): void => {
      if (!isExist(planBoardHeaderTableRef.value)) {
        return;
      }
      planBoardHeaderTableRef.value.scrollTop = v.target.scrollTop;
    };

    const inputNumberOnly = (
      target: Record<string, number>,
      key: string,
      maxIntegerDigits: number = 20,
      maxDecimalDigits: number = 0,
    ): void => {
      if (!isExist(target[key])) {
        return;
      }
      // ピリオドで終わる場合は、数値変換して state を更新するとピリオドが消えてしまうため更新しない。
      if (maxDecimalDigits > 0 && /^\d*\.$/.test(target[key].toString())) {
        return;
      }

      const [integerPart, decimalPart = ''] = target[key]
        .toString()
        .replace(maxDecimalDigits > 0 ? /[^\d.]/g : /\D/g, '')
        .split('.');
      target[key] = Number(
        `${integerPart.slice(0, maxIntegerDigits) || 0}.${decimalPart.slice(0, maxDecimalDigits) || 0}`,
      );
    };

    const inputTimeOnly = (target: Record<string, string>, key: string): void => {
      if (!isExist(target[key])) {
        return;
      }
      target[key] = target[key].toString().replace(/[^\d:]*/g, '');
    };

    const onTimeRangeInputBlur = (range: { startTime: number; endTime: number }): void => {
      state.targetStartTime = range.startTime;
      state.targetEndTime = range.endTime;

      // 絞り込み時間変更時にもサマリ欄を更新する https://github.com/kurando-inc/logiboard-ap/issues/608
      const planBoardResponse = state.apiResponse;
      if (isExist(planBoardResponse)) {
        updateOverViewState(planBoardResponse);
      }
    };

    const onShiftInputBlur = async (shiftIndex: number): Promise<void> => {
      await updateShiftInput(shiftIndex);
      refreshPlanBoard();
    };

    const displayTimeRange = (): string => {
      return `${unpackTimeIntegerToStringFormat(state.targetStartTime)} - ${unpackTimeIntegerToStringFormat(
        state.targetEndTime,
      )}`;
    };

    const createTimetableHeader = async (): Promise<void> => {
      if (!state.budgetGroup || !state.baseDate) {
        return;
      }

      try {
        const data = await planBoardApi.create({
          workplace_id: state.workplaceId,
          dt: state.baseDate,
          budget_group_id: state.budgetGroup.id,
          use_in_prep_phase: true,
          use_in_review_phase: null,
        });

        state.timetables = createTimetablesFromApiResponse(
          data,
          state.blockLengthPerHour,
          state.displayHourPeriod,
          state.workplaceExtension,
        );
        notifySuccess1(root, '入力準備ができました');
      } catch {
        notifyError1(root, 'システムエラーが発生しました。管理者に連絡してください');
      }
    };

    const linkTo = (pageName: string) => {
      root.$router.push({ name: pageName });
    };

    return {
      state,
      overviewState,
      headcountMoveModalState,
      planBoardTimelineStyle,
      planBoardTimelineRef,
      planBoardHeaderTableRef,
      planBoardTimelineTableRef,
      routeName,
      dailyBoardPageOptions,
      allocateStaffs,
      allocateStaffsEvenly,
      saveToDB,
      refreshPlanBoard,
      roundValue,
      onDragstart,
      onDrop,
      onPlanBoardHeaderTableScroll,
      onPlanBoardTimelineTableScroll,
      onTimeRangeInputBlur,
      onShiftInputBlur,
      getHeadcountIndex,
      updateTimetable,
      updateShiftInput,
      updateMemo,
      isExist,
      inputTimeOnly,
      inputNumberOnly,
      displayTimeRange,
      PLAN_BOARD_SURPLUS_MASTER_ID,
      PLAN_BOARD_BREAK_TIME_MASTER_ID,
      createTimetableHeader,
      formatExcessOrDeficiency,
      linkTo,
    };
  },
});
