import { format } from 'date-fns';
import type { StaffGender, StaffType } from 'src/consts';
import { unpackTimeIntegerToString } from 'src/util/datetime';
import type { TimeInteger } from 'src/models/common';
import type { StaffWorkSchedule } from 'src/models/staffWorkSchedule';
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern';

export type Operation = 'update' | 'bulkUpdate';
export type WeekKey = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type WeekName = '月' | '火' | '水' | '木' | '金' | '土' | '日';
export type WeekValue = 1 | 2 | 3 | 4 | 5 | 6 | 0;
export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 0;

export type Week = { key: WeekKey; name: WeekName; value: number }[];

export type LabelName =
  | 'work_start_time'
  | 'work_end_time'
  | 'break1_start_time'
  | 'break1_end_time'
  | 'break2_start_time'
  | 'break2_end_time';

export type StaffWorkScheduleSubset = Pick<
  StaffWorkSchedule,
  | 'day_of_week'
  | 'work_start_time'
  | 'work_end_time'
  | 'break1_start_time'
  | 'break1_end_time'
  | 'break2_start_time'
  | 'break2_end_time'
  | 'duty1_macro_operation_master_id'
  | 'duty1_start_time'
  | 'duty1_end_time'
  | 'duty2_macro_operation_master_id'
  | 'duty2_start_time'
  | 'duty2_end_time'
>;

export interface StaffWorkScheduleFormValues {
  day_of_week: number;
  work_start_time_hour: string | null;
  work_start_time_min: string | null;
  work_end_time_hour: string | null;
  work_end_time_min: string | null;
  break1_start_time_hour: string | null;
  break1_start_time_min: string | null;
  break1_end_time_hour: string | null;
  break1_end_time_min: string | null;
  break2_start_time_hour: string | null;
  break2_start_time_min: string | null;
  break2_end_time_hour: string | null;
  break2_end_time_min: string | null;
  duty1_start_time_hour: string | null;
  duty1_start_time_min: string | null;
  duty1_end_time_hour: string | null;
  duty1_end_time_min: string | null;
  duty2_start_time_hour: string | null;
  duty2_start_time_min: string | null;
  duty2_end_time_hour: string | null;
  duty2_end_time_min: string | null;
  duty1_macro_operation_master_id: number | null;
  duty2_macro_operation_master_id: number | null;
}

export interface SaveCandidate {
  staff_id: number | null;
  is_key_player: boolean | null;
  is_forkman: boolean | null;
  gender: StaffGender | null;
  staff_type: StaffType | null;
  macro_operation_master_id: number | null;
  has_custom_skill1: boolean | null;
  has_custom_skill2: boolean | null;
  has_custom_skill3: boolean | null;
  staff_work_schedules: StaffWorkScheduleSubset[];
  saveType: Operation;
  staff_skills: { timetable_master_id: number | null; priority: number }[];
  is_cleared: [];
  staff_ids: number[];
  memo: string | null;
}

// StaffWorkScheduleFormValuesへ変換
export const convertToStaffWorkScheduleFormValues = (
  staffWorkSchedules: StaffWorkScheduleSubset,
): StaffWorkScheduleFormValues => {
  return {
    day_of_week: staffWorkSchedules.day_of_week,
    work_start_time_hour: timeIntegerToString(staffWorkSchedules.work_start_time)[0],
    work_start_time_min: timeIntegerToString(staffWorkSchedules.work_start_time)[1],
    work_end_time_hour: timeIntegerToString(staffWorkSchedules.work_end_time)[0],
    work_end_time_min: timeIntegerToString(staffWorkSchedules.work_end_time)[1],
    break1_start_time_hour: timeIntegerToString(staffWorkSchedules.break1_start_time)[0],
    break1_start_time_min: timeIntegerToString(staffWorkSchedules.break1_start_time)[1],
    break1_end_time_hour: timeIntegerToString(staffWorkSchedules.break1_end_time)[0],
    break1_end_time_min: timeIntegerToString(staffWorkSchedules.break1_end_time)[1],
    break2_start_time_hour: timeIntegerToString(staffWorkSchedules.break2_start_time)[0],
    break2_start_time_min: timeIntegerToString(staffWorkSchedules.break2_start_time)[1],
    break2_end_time_hour: timeIntegerToString(staffWorkSchedules.break2_end_time)[0],
    break2_end_time_min: timeIntegerToString(staffWorkSchedules.break2_end_time)[1],
    duty1_start_time_hour: timeIntegerToString(staffWorkSchedules.duty1_start_time)[0],
    duty1_start_time_min: timeIntegerToString(staffWorkSchedules.duty1_start_time)[1],
    duty1_end_time_hour: timeIntegerToString(staffWorkSchedules.duty1_end_time)[0],
    duty1_end_time_min: timeIntegerToString(staffWorkSchedules.duty1_end_time)[1],
    duty2_start_time_hour: timeIntegerToString(staffWorkSchedules.duty2_start_time)[0],
    duty2_start_time_min: timeIntegerToString(staffWorkSchedules.duty2_start_time)[1],
    duty2_end_time_hour: timeIntegerToString(staffWorkSchedules.duty2_end_time)[0],
    duty2_end_time_min: timeIntegerToString(staffWorkSchedules.duty2_end_time)[1],
    duty1_macro_operation_master_id: staffWorkSchedules.duty1_macro_operation_master_id,
    duty2_macro_operation_master_id: staffWorkSchedules.duty2_macro_operation_master_id,
  };
};

export const formatDate = (date: Date | null): string | null => {
  if (!date) {
    return null;
  }
  return format(date, 'yyyy/MM/dd');
};

// 0表示ではなく空表示になるようにwrap
export const timeIntegerToString = (timeInteger: TimeInteger | null) => {
  if (timeInteger === null) {
    return ['', '', ''];
  }
  return unpackTimeIntegerToString(timeInteger);
};
// 曜日ごとのスケジュールを検索
export function findDayOfWeekSchedule(
  workSchedules: StaffWorkSchedule[] | undefined,
  num: number,
): StaffWorkSchedule | null {
  return workSchedules?.find((workSchedule) => workSchedule.day_of_week === num) ?? null;
}

export function getDayValue(name: string): number {
  const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  return dayOfWeek.indexOf(name);
}

export function updateCandidateByShiftPattern(
  saveCandidate: SaveCandidate,
  staffWorkScheduleFormValues: StaffWorkScheduleFormValues[],
  selectedShifts: WeekKey[],
  shiftPattern: ShiftPattern,
) {
  const [startTimeHour, startTimeMin] = timeIntegerToString(shiftPattern.start_time);
  const [endTimeHour, endTimeMin] = timeIntegerToString(shiftPattern.end_time);
  const [break1StartTimeHour, break1StartTimeMin] = timeIntegerToString(shiftPattern.break1_start_time);
  const [break1EndTimeHour, break1EndTimeMin] = timeIntegerToString(shiftPattern.break1_end_time);
  const [break2StartTimeHour, break2StartTimeMin] = timeIntegerToString(shiftPattern.break2_start_time);
  const [break2EndTimeHour, break2EndTimeMin] = timeIntegerToString(shiftPattern.break2_end_time);
  selectedShifts.forEach((weekKey) => {
    const dayOfWeek = getDayValue(weekKey);
    const staffWorkSchedule = saveCandidate.staff_work_schedules?.find((obj) => obj.day_of_week === dayOfWeek);
    if (staffWorkSchedule === undefined) {
      return;
    }
    const staffWorkScheduleFromValue = staffWorkScheduleFormValues.find(
      (formValues) => formValues.day_of_week === dayOfWeek,
    );
    if (staffWorkScheduleFromValue === undefined) {
      return;
    }

    staffWorkSchedule.work_start_time = shiftPattern.start_time;
    staffWorkSchedule.work_end_time = shiftPattern.end_time;
    staffWorkSchedule.break1_start_time = shiftPattern.break1_start_time;
    staffWorkSchedule.break1_end_time = shiftPattern.break1_end_time;
    staffWorkSchedule.break2_start_time = shiftPattern.break2_start_time;
    staffWorkSchedule.break2_end_time = shiftPattern.break2_end_time;

    staffWorkScheduleFromValue.work_start_time_hour = startTimeHour;
    staffWorkScheduleFromValue.work_start_time_min = startTimeMin;
    staffWorkScheduleFromValue.work_end_time_hour = endTimeHour;
    staffWorkScheduleFromValue.work_end_time_min = endTimeMin;
    staffWorkScheduleFromValue.break1_start_time_hour = break1StartTimeHour;
    staffWorkScheduleFromValue.break1_start_time_min = break1StartTimeMin;
    staffWorkScheduleFromValue.break1_end_time_hour = break1EndTimeHour;
    staffWorkScheduleFromValue.break1_end_time_min = break1EndTimeMin;
    staffWorkScheduleFromValue.break2_start_time_hour = break2StartTimeHour;
    staffWorkScheduleFromValue.break2_start_time_min = break2StartTimeMin;
    staffWorkScheduleFromValue.break2_end_time_hour = break2EndTimeHour;
    staffWorkScheduleFromValue.break2_end_time_min = break2EndTimeMin;
  });
}
