import { ref, unref, readonly, type Ref, type DeepReadonly } from '@vue/composition-api';
import backgroundProcessStatusApi from 'src/apis/backgroundProcessStatus';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import {
  type BackgroundProcessStatus,
  convertBackgroundProcessStatusIndexResponse,
} from 'src/models/backgroundProcessStatus';
import { createInjection } from 'src/util/createInjection';
import { type DateRange } from '../values/DateRange';
import { endOfDay, startOfDay } from 'src/util/datetime';

type Params = {
  workplaceId: number;
  shouldFetchImmediately: boolean;
};

type InjectionValue = {
  backgroundProcessStatuses: DeepReadonly<Ref<BackgroundProcessStatus[] | null>>;
  dateRange: Ref<DateRange>;
  budgetGroup: Ref<BudgetGroup | null>;
  processType: Ref<BackgroundProcessStatus['process_type'] | null>;
  isLoading: Ref<boolean>;
  fetchBackgroundProcessStatuses: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useBackgroundProcessStatuses');

async function getBackgroundProcessStatuses({
  workplaceId,
  dateRange,
  budgetGroup,
  processType,
}: {
  workplaceId: number;
  dateRange: DateRange;
  budgetGroup: BudgetGroup | null;
  processType: BackgroundProcessStatus['process_type'] | null;
}): Promise<BackgroundProcessStatus[]> {
  const { data } = await backgroundProcessStatusApi.index({
    workplace_id: workplaceId,
    start_dt: startOfDay(dateRange.startDate),
    end_dt: endOfDay(dateRange.endDate),
    budget_group_id: budgetGroup?.id ?? null,
    process_type: processType,
  });

  return convertBackgroundProcessStatusIndexResponse(data);
}

export function useBackgroundProcessStatusesProvider({ workplaceId, shouldFetchImmediately }: Params): void {
  const errorBoundary = useErrorBoundary();
  const backgroundProcessStatuses = ref<BackgroundProcessStatus[] | null>(null);
  const dateRange = ref<DateRange>({ startDate: new Date(), endDate: new Date() });
  const budgetGroup = ref<BudgetGroup | null>(null);
  const processType = ref<BackgroundProcessStatus['process_type'] | null>(null);
  const isLoading = ref(false);
  const fetchBackgroundProcessStatuses = errorBoundary.wrap(
    async () => {
      const unwrappedDateRange = unref(dateRange);
      const unwrappedBudgetGroup = unref(budgetGroup);
      const unwrappedProcessType = unref(processType);
      try {
        isLoading.value = true;
        backgroundProcessStatuses.value = await getBackgroundProcessStatuses({
          workplaceId,
          dateRange: unwrappedDateRange,
          budgetGroup: unwrappedBudgetGroup,
          processType: unwrappedProcessType,
        });
      } finally {
        isLoading.value = false;
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  if (shouldFetchImmediately) {
    (async () => await fetchBackgroundProcessStatuses())();
  }

  provide({
    backgroundProcessStatuses: readonly(backgroundProcessStatuses),
    dateRange,
    budgetGroup,
    processType,
    isLoading: readonly(isLoading),
    fetchBackgroundProcessStatuses,
  });
}

export function useBackgroundProcessStatuses(): InjectionValue {
  return inject();
}
