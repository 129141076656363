import { StaffWorkScheduleFormValues, Week } from '../setting';

type UseDutyTimeValidationResult = {
  rules: Record<string, Record<string, unknown>>;
};

export const useDutyTimeValidation = (
  week: Week,
  formValues: StaffWorkScheduleFormValues[],
): UseDutyTimeValidationResult => {
  const ruleHour = { numeric: true, max: 2, max_value: 47 };
  const ruleMin = { numeric: true, max: 2, regex: /^(00|15|30|45)$/ };

  const getMinEndHour = (startHour: string | null, startMin: string | null, endMin: string | null): number => {
    if (startHour === null || startMin === null || endMin === null) {
      return 0;
    }

    return Number(startMin) < Number(endMin) ? Number(startHour) : Number(startHour) + 1;
  };

  const getMaxStartHour = (startMin: string | null, endHour: string | null, endMin: string | null): number => {
    if (startMin === null || endHour === null || endMin === null) {
      return 47;
    }

    return Number(startMin) < Number(endMin) ? Number(endHour) : Number(endHour) - 1;
  };

  const rules = week.reduce<Record<string, Record<string, unknown>>>((rules, day) => {
    const formValue = formValues.find((formValue) => formValue.day_of_week === day.value);
    if (formValue === undefined) {
      return rules;
    }

    const isDuty1Present = Boolean(formValue.duty1_macro_operation_master_id);
    const isDuty2Present = Boolean(formValue.duty2_macro_operation_master_id);

    const duty1MaxStartHour = getMaxStartHour(
      formValue.duty1_start_time_min,
      formValue.duty1_end_time_hour,
      formValue.duty1_end_time_min,
    );
    const duty1MinEndHour = getMinEndHour(
      formValue.duty1_start_time_hour,
      formValue.duty1_start_time_min,
      formValue.duty1_end_time_min,
    );
    const duty2MaxStartHour = getMaxStartHour(
      formValue.duty2_start_time_min,
      formValue.duty2_end_time_hour,
      formValue.duty2_end_time_min,
    );
    const duty2MinEndHour = getMinEndHour(
      formValue.duty2_start_time_hour,
      formValue.duty2_start_time_min,
      formValue.duty2_end_time_min,
    );

    return {
      ...rules,
      [`${day.key}_duty1_start_time_hour`]: { ...ruleHour, required: isDuty1Present, max_value: duty1MaxStartHour },
      [`${day.key}_duty1_start_time_min`]: { ...ruleMin, required: isDuty1Present },
      [`${day.key}_duty1_end_time_hour`]: { ...ruleHour, required: isDuty1Present, min_value: duty1MinEndHour },
      [`${day.key}_duty1_end_time_min`]: { ...ruleMin, required: isDuty1Present },
      [`${day.key}_duty2_start_time_hour`]: { ...ruleHour, required: isDuty2Present, max_value: duty2MaxStartHour },
      [`${day.key}_duty2_start_time_min`]: { ...ruleMin, required: isDuty2Present },
      [`${day.key}_duty2_end_time_hour`]: { ...ruleHour, required: isDuty2Present, min_value: duty2MinEndHour },
      [`${day.key}_duty2_end_time_min`]: { ...ruleMin, required: isDuty2Present },
    };
  }, {});

  return {
    rules,
  };
};
