

































































import { PropType, defineComponent } from '@vue/composition-api';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import StaffExperienceMatrixOperationItem from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffExperienceMatrixOperationItem/StaffExperienceMatrixOperationItem.vue';
import { StaffWithTimetableMasterSummaries } from 'src/models/staffExperienceOverview';
import { STAFF_GENDER_NAME } from 'src/consts';
import KeyPlayerGrayIcon from 'src/components/Staffs/keyPlayerGrayIcon.vue';
import ForkliftGrayIcon from 'src/components/Staffs/forkliftGrayIcon.vue';
import CustomFlagGrayIcon from 'src/components/Staffs/customFlagGrayIcon.vue';
import { useStaffSkill } from '../../composables/useStaffSkill';

export default defineComponent({
  components: {
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
    KeyPlayerGrayIcon,
    ForkliftGrayIcon,
    CustomFlagGrayIcon,
    StaffExperienceMatrixOperationItem,
  },
  props: {
    staffWithDailySummary: {
      type: Object as PropType<StaffWithTimetableMasterSummaries>,
      required: true,
    },
  },
  setup(props) {
    const { enabledCustomSkill1, enabledCustomSkill2, enabledCustomSkill3 } = useStaffSkill();
    const gender = STAFF_GENDER_NAME[props.staffWithDailySummary.staff_extension.gender];
    const macroOperationMasterName = props.staffWithDailySummary.staff_extension.macro_operation_master?.name ?? '';
    const experienceCount = props.staffWithDailySummary.timetable_master_summaries.filter((e) => {
      return Math.round(e.work_hours) > 0;
    }).length;

    const staffLabelStyle = {
      backgroundColor: '#' + props.staffWithDailySummary.staff_label?.disp_color ?? 'white',
      color: props.staffWithDailySummary.staff_label?.disp_color ? 'white' : 'black',
    };
    const macroOperationMasterStyle = {
      backgroundColor: '#' + props.staffWithDailySummary.staff_extension.macro_operation_master?.disp_color ?? 'white',
    };

    return {
      gender,
      macroOperationMasterStyle,
      macroOperationMasterName,
      experienceCount,
      staffLabelStyle,
      enabledCustomSkill1,
      enabledCustomSkill2,
      enabledCustomSkill3,
    };
  },
});
