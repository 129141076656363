import { ref, readonly, type Ref, type DeepReadonly } from '@vue/composition-api'
import shiftPatternApi from 'src/apis/workplace_masters/shiftPattern'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { convertShiftPatternIndexResponse, type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'

type ShiftPatternSelectModal = {
  shiftPatterns: DeepReadonly<Ref<ShiftPattern[]>>
  showsShiftPatternSelectModal: DeepReadonly<Ref<boolean>>
  showShiftPatternSelectModal: (budgetGroup: BudgetGroup) => void
  hideShiftPatternSelectModal: () => void
}

export function useShiftPatternSelectModal(): ShiftPatternSelectModal {
  const errorBoundary = useErrorBoundary()
  const shiftPatterns = ref<ShiftPattern[]>([])
  const showsShiftPatternSelectModal = ref(false)
  const fetchShiftPatterns = errorBoundary.wrap(
    async(budgetGroup: BudgetGroup) => {
      const { data } = await shiftPatternApi.index({
        workplace_id: budgetGroup.workplace_id,
        budget_group_id: budgetGroup.id,
        is_enabled: true,
      })
      shiftPatterns.value = convertShiftPatternIndexResponse(data)
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    }
  )
  const showShiftPatternSelectModal = (budgetGroup: BudgetGroup) => {
    showsShiftPatternSelectModal.value = true
    ;(async() => await fetchShiftPatterns(budgetGroup))()
  }
  const hideShiftPatternSelectModal = () => {
    showsShiftPatternSelectModal.value = false
    shiftPatterns.value = []
  }

  return {
    shiftPatterns: readonly(shiftPatterns),
    showsShiftPatternSelectModal: readonly(showsShiftPatternSelectModal),
    showShiftPatternSelectModal,
    hideShiftPatternSelectModal,
  }
}
