



































import { defineComponent } from '@vue/composition-api'
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect'
import { useBudgetGroups } from 'src/composables/useBudgetGroups'
import { DateRangeSelect } from '../DateRangeSelect'
import { ProcessTypeSelect } from '../ProcessTypeSelect'
import { SearchButton } from '../SearchButton'
import { useBackgroundProcessStatuses } from '../../composables/useBackgroundProcessStatuses'

export default defineComponent({
  components: {
    DateRangeSelect,
    BudgetGroupSelect,
    ProcessTypeSelect,
    SearchButton,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups()
    const {
      dateRange,
      budgetGroup,
      processType,
      isLoading,
      fetchBackgroundProcessStatuses,
    } = useBackgroundProcessStatuses()

    return {
      budgetGroups,
      dateRange,
      budgetGroup,
      processType,
      isLoading,
      fetchBackgroundProcessStatuses,
    }
  },
})
