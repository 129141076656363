


















import { defineComponent, PropType } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useSearchConditions } from '../composables/useSearchConditions';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    PrimaryButton,
  },
  props: {
    onSearchButtonClick: {
      type: Function as PropType<() => {}>,
      required: true,
    },
    clickCreateProgressHeadersButton: {
      type: Function as PropType<() => {}>,
      required: true,
    },
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const { dt, budgetGroup } = useSearchConditions();

    return {
      budgetGroups,
      dt,
      budgetGroup,
    };
  },
});
