


















import { PropType, defineComponent, ref, watch } from '@vue/composition-api';
import { FormItem } from 'src/components/UIComponents/Form';
import { TimeInteger } from 'src/models/common';
import { formatTimeInteger, timeStrToTimeInteger } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';

export default defineComponent({
  inheritAttrs: false,
  components: {
    FormItem,
  },
  props: {
    value: {
      type: Number as PropType<number | null>,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    regexRule: {
      type: RegExp,
      default: () => /^([0-9]|[0-3][0-9]|[0-4][0-7]):([0-5][0-9])$/,
    },
  },
  setup(props, context) {
    const valueRef = ref(formatTimeIntegerWithNull(props.value));
    const lastValue = ref(formatTimeIntegerWithNull(props.value));

    watch(
      () => props.value,
      (newValue) => {
        valueRef.value = formatTimeIntegerWithNull(newValue);
        lastValue.value = formatTimeIntegerWithNull(newValue);
      },
    );

    const input = ref<{ $el: HTMLInputElement } | null>(null);

    const onBlur = (event: FocusEvent) => {
      if (event.target instanceof HTMLInputElement) {
        if (!event.target.className.includes('is-invalid') && valueRef.value !== lastValue.value) {
          const timeInteger = valueRef.value.length === 0 ? null : timeStrToTimeInteger(valueRef.value);
          context.emit('input', timeInteger);
          context.emit('update', timeInteger);
        }
      }
      context.emit('blur', event);
    };

    return {
      valueRef,
      input,
      onBlur,
    };
  },
});

const formatTimeIntegerWithNull = (time: TimeInteger | null) => {
  if (!isExist(time)) {
    return '';
  }
  return formatTimeInteger(time);
};
