import { createInjection } from 'src/util/createInjection';
import { useMonthlyPlan } from './useMonthlyPlan';

type InjectionValue = {
  getIsMinusAvailable: (key: string) => boolean;
  onValueInput: (value: string, key: string) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useInputValidations');

// integerPartMaxDigits 桁より大きい数値や、小数点第 1 位より下の数値が入力された場合に超過部分を削除するための regex
const getNumberRegex = (integerPartMaxDigits: number, allowMinus = false): RegExp => {
  // 先頭の ^${allowMinus ? '' : '[^\\d]*'} この部分は、allowMinus が false の時に - 始まりの数値がコピペされたりすると、この判定にマッチしなくなってしまうので、allowMinus が false の時は先頭から数値以外の文字列を除外するためのもの
  const p1 = `^${allowMinus ? '' : '[^\\d]*'}(${allowMinus ? '-?' : ''}\\d{1,${integerPartMaxDigits}})\\d*`;
  const p2 = '(\\.\\d)?(\\d*)$';
  return new RegExp(`${p1}${p2}`);
};

// 数値以外の文字を削除する
const eraseNonNumberChars = (srcStr: string, ignoreChars: string[] = []): string => {
  const reg = new RegExp(`[^\\d${ignoreChars.join('')}]`, 'g');
  return srcStr.replace(reg, '');
};

export function useInputValidationsProvider(): void {
  const { state } = useMonthlyPlan();

  const getIsMinusAvailable = (key: string) => {
    return ['spot4ManHours', 'spot4Headcount', 'indirectBalance'].includes(key);
  };

  const onValueInput = (value: string, key: string = '') => {
    const isMinusAvailable = getIsMinusAvailable(key);
    // 数値とピリオド以外の文字が入力された場合も削除して数値変換
    const ignoreChars: string[] = [];
    let replacedString = value;
    if (
      [
        'actualManHours',
        'plannedProductivity',
        'spot1ManHours',
        'spot2ManHours',
        'spot3ManHours',
        'spot4ManHours',
        'regularActualManHours',
        'spotActualManHours',
      ].includes(key)
    ) {
      // 5 桁より大きい数値や、小数点第 1 位より下の数値が入力された場合は削除する。
      replacedString = replacedString.replace(getNumberRegex(5, isMinusAvailable), '$1$2');
      ignoreChars.push('.');
    } else if (['actualHeadcount', 'regularActualHeadcount', 'spotActualHeadcount'].includes(key)) {
      // 4 桁より大きい数値や、小数点第 1 位より下の数値が入力された場合は削除する。
      replacedString = replacedString.replace(getNumberRegex(4, isMinusAvailable), '$1$2');
      ignoreChars.push('.');
    } else if (['actualRevenue', 'actualCost', 'indirectBalance'].includes(key)) {
      // 10 桁より大きい数値が入力された場合は削除する。
      replacedString = replacedString.replace(getNumberRegex(10, isMinusAvailable), '$1');
    } else if (['actualQuantity', 'plannedInitialQuantity', 'plannedFinalQuantity'].includes(key)) {
      replacedString = replacedString.replace(getNumberRegex(9, isMinusAvailable), '$1');
    }
    // マイナスを許容する必要がある場合は残す
    if (isMinusAvailable) {
      ignoreChars.push('-');
    }
    // 数値以外の文字を削除する
    return eraseNonNumberChars(replacedString, ignoreChars);
  };

  provide({
    getIsMinusAvailable,
    onValueInput,
  });
}

export function useInputValidations(): InjectionValue {
  return inject();
}
