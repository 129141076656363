import { SidebarLink, UnresolvedSidebarLink } from 'src/components/UIComponents/Sidebar/createSidebarStore'

export const resolvedSidebarLinksMap: Record<string, SidebarLink[]> = {
  top: [
    {
      name: 'センター',
      nameMini: 'センター',
      icon: 'fal fa-warehouse-alt',
      path: '/workplace/select',
      activePath: '/workplace/select',
    },
    {
      name: '共通設定',
      nameMini: '共通設定',
      icon: 'fal fa-cog',
      path: '/settings',
      activePath: '/settings',
      toLogiCoredata: true,
    },
  ],
  superAdmin: [
    {
      name: 'スーパーメンテ',
      nameMini: 'メンテ',
      icon: 'fal fa-tools',
      path: '/super-maintenance',
      activePath: '/super-maintenance',
    },
  ]
}

export const unresolvedSidebarLinksMap: Record<string, UnresolvedSidebarLink[]> = {
  workplace: [
    {
      name: '機能マップ',
      nameMini: '機能',
      icon: 'fal fa-compass',
      pathCandMap: {
        default: '/workplace/:workplaceId/map',
      },
      alwaysLinkToDefault: true,
    },
    // TODO: トライアルであまり評価されなかったため、一度非表示にして再度利用してもらい、様子を見る。その結果によっては削除する。
    // {
    //   name: '応援調整',
    //   nameMini: '応援調整',
    //   icon: 'fal fa-users',
    //   pathCandMap: {
    //     default: '/workplace/:workplaceId/:date/rescue-adjustment',
    //   },
    //   alwaysLinkToDefault: true,
    // },
    {
      name: 'データ変換',
      nameMini: 'データ変換',
      icon: 'fal fa-sync-alt',
      pathCandMap: {
        default: '/workplace/:workplaceId/conversion-programs',
      },
      alwaysLinkToDefault: true,
    },
    // TODO: 削除予定??
    // {
    //   name: '配置変更',
    //   nameMini: '配置変更',
    //   icon: 'fal fa-random',
    //   pathCandMap: {
    //     default: '/workplace/:workplaceId/:date/assignment-simulation',
    //   },
    //   alwaysLinkToDefault: true,
    // },
    {
      name: '作成状況',
      nameMini: '作成状況',
      icon: 'fal fa-hourglass-half',
      pathCandMap: {
        default: '/workplace/:workplaceId/background-process-status',
      },
    },
    {
      name: '旧 進捗',
      nameMini: '旧 進捗',
      icon: 'fal fa-table',
      pathCandMap: {
        default: '/workplace/:workplaceId/:date/plan-progress',
      },
      alwaysLinkToDefault: true,
    },
    {
      name: '旧 一覧',
      nameMini: '旧 一覧',
      icon: 'fal fa-tachometer',
      pathCandMap: {
        default: '/workplace/:workplaceId/:date/progress-board',
      },
      alwaysLinkToDefault: true,
    },
    {
      name: '設定',
      nameMini: '設定',
      icon: 'fal fa-cog',
      pathCandMap: {
        default: '/workplace/:workplaceId/settings',
      },
    },
  ],
}
