







































import Vue from 'vue';
import moment from 'src/util/moment-ja';
import { defineComponent, reactive, SetupContext, PropType } from '@vue/composition-api';
import { SEARCH_DATE_RANGE_MAX } from 'src/consts';
import { notifyError1 } from 'src/hooks/notificationHook';

interface DateOptionType {
  key: string;
  label: string;
}

interface State {
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
  dateOptionCandidates: DateOptionType[];
  selectedDateOptionKey: string | null;
  displayDateOption: boolean;
}

export default defineComponent({
  props: {
    dateStart: {
      type: String as PropType<string>,
      required: false,
    },
    dateEnd: {
      type: String as PropType<string>,
      required: false,
    },
    displayDateOption: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    dateRangeMax: {
      type: Number,
      default: SEARCH_DATE_RANGE_MAX,
    },
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    const state: State = reactive({
      dateStart: moment(props.dateStart),
      dateEnd: moment(props.dateEnd),
      dateOptionCandidates: [
        { key: 'today', label: '今日' },
        { key: 'this_week', label: '今週' },
        { key: 'this_month', label: '今月' },
        { key: 'yesterday', label: '昨日' },
        { key: 'last_week', label: '先週' },
        { key: 'last_month', label: '先月' },
        { key: 'tomorrow', label: '明日' },
        { key: 'next_week', label: '来週' },
        { key: 'next_month', label: '来月' },
      ],
      selectedDateOptionKey: null,
      displayDateOption: props.displayDateOption,
    });

    function onDateStartChange() {
      state.selectedDateOptionKey = null;
      state.dateStart = moment(state.dateStart);
      if (state.dateStart.isAfter(state.dateEnd, 'day')) {
        notifyError1(root, '開始日を終了日よりも前の日に指定してください');
      } else if (isDateRangeMaxExceeded()) {
        // if date range exceeds the specified range,
        // move the dateEnd to fit the range
        state.dateEnd = state.dateStart.clone().add(props.dateRangeMax - 1, 'days');
        syncDateEnd();
      }
      syncDateStart();
      syncDate();
    }

    function onDateEndChange() {
      state.selectedDateOptionKey = null;
      state.dateEnd = moment(state.dateEnd);
      if (state.dateEnd.isBefore(state.dateStart, 'day')) {
        notifyError1(root, '開始日を終了日よりも前の日に指定してください');
      } else if (isDateRangeMaxExceeded()) {
        // if date range exceeds the specified range,
        // move the dateStart to fit the range
        state.dateStart = state.dateEnd.clone().subtract(props.dateRangeMax - 1, 'days');
        syncDateStart();
      }
      syncDateEnd();
      syncDate();
    }

    function isDateRangeMaxExceeded() {
      return state.dateEnd.diff(state.dateStart, 'days') >= props.dateRangeMax;
    }

    function syncDateStart() {
      context.emit('on-date-start-change', state.dateStart.format('YYYY-MM-DD'));
    }

    function syncDateEnd() {
      context.emit('on-date-end-change', state.dateEnd.format('YYYY-MM-DD'));
    }

    function syncDate() {
      context.emit('on-date-change', state.dateStart.format('YYYY-MM-DD'), state.dateEnd.format('YYYY-MM-DD'));
    }

    function onDateOptionChange() {
      const today = moment();
      if (state.selectedDateOptionKey === 'today') {
        state.dateStart = today;
        state.dateEnd = today;
      }
      if (state.selectedDateOptionKey === 'this_week') {
        state.dateStart = today.clone().startOf('isoweek');
        state.dateEnd = state.dateStart.clone().add(6, 'days');
      } else if (state.selectedDateOptionKey === 'this_month') {
        state.dateStart = today.startOf('month');
        state.dateEnd = today.clone().endOf('month');
      } else if (state.selectedDateOptionKey === 'yesterday') {
        const yesterday = today.subtract(1, 'day');
        state.dateStart = yesterday;
        state.dateEnd = yesterday;
      } else if (state.selectedDateOptionKey === 'last_week') {
        state.dateStart = today.subtract(7, 'days').startOf('isoweek');
        state.dateEnd = state.dateStart.clone().add(6, 'days');
      } else if (state.selectedDateOptionKey === 'last_month') {
        state.dateStart = today.subtract(1, 'month').startOf('month');
        state.dateEnd = state.dateStart.clone().endOf('month');
      } else if (state.selectedDateOptionKey === 'tomorrow') {
        const tomorrow = today.add(1, 'day');
        state.dateStart = tomorrow;
        state.dateEnd = tomorrow;
      } else if (state.selectedDateOptionKey === 'next_week') {
        state.dateStart = today.add(7, 'days').startOf('isoweek');
        state.dateEnd = state.dateStart.clone().add(6, 'days');
      } else if (state.selectedDateOptionKey === 'next_month') {
        state.dateStart = today.add(1, 'month').startOf('month');
        state.dateEnd = state.dateStart.clone().endOf('month');
      }
      // sync to parent component
      syncDateStart();
      syncDateEnd();
      syncDate();
    }

    return {
      state,
      onDateStartChange,
      onDateEndChange,
      onDateOptionChange,
      syncDateStart,
      syncDateEnd,
    };
  },
});
