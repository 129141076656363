








































import { defineComponent, onMounted, ref, SetupContext, watch } from '@vue/composition-api';
import { MonthRangePicker } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/MonthRangePicker';
import { StaffLabelSelect } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffLabelSelect';
import { MacroOperationMasterSelect } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/MacroOperationMasterSelect';
import { GenderSelect } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/GenderSelect';
import { CustomFlags } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/CustomFlags';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { MonthRange } from 'src/models/staffExperienceOverview';
import { getInitialStartAndEndMonth } from '../../libs/dateGenerator';
import Vue from 'vue';
import { BudgetGroup } from 'src/models/budgetGroup';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { StaffLabel } from 'src/models/staffLabel';
import staffLabelApi from 'src/apis/workplace_masters/staff_label';
import { StaffGender } from 'src/consts';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { useStaffSkill } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffSkill';
import { SKILL_MAP } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/consts';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import { generateCheckedSkillsList } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/libs/skillOptionsGenerator';
import { useStaffExperience } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffExperience';
import { notifyError1 } from 'src/hooks/notificationHook';
import { parseYmdDate } from 'src/util/datetime';

export default defineComponent({
  components: {
    MonthRangePicker,
    BudgetGroupSelect,
    StaffLabelSelect,
    MacroOperationMasterSelect,
    GenderSelect,
    CustomFlags,
    PrimaryButton,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    const { changeEnabledSkill, skillOptions } = useStaffSkill();
    const { fetchStaffExperience } = useStaffExperience();
    const monthRange = ref<MonthRange>(getInitialStartAndEndMonth());
    const workplaceId = ref<number>(Number(root.$route.params.workplaceId));
    const budgetGroups = ref<BudgetGroup[]>([]);
    const budgetGroup = ref<BudgetGroup>(budgetGroups.value[0]);
    const staffLabel = ref<StaffLabel | null>(null);
    const staffLabels = ref<StaffLabel[]>([]);
    const gender = ref<StaffGender | null>(null);
    const macroOperationMaster = ref<MacroOperationMaster | null>(null);
    const macroOperationMasters = ref<MacroOperationMaster[]>([]);

    onMounted(async () => {
      budgetGroups.value = await budgetGroupApi.index(workplaceId.value);
      budgetGroup.value = budgetGroups.value[0];
      const { data } = await staffLabelApi.index({
        workplaceId: workplaceId.value,
        params: { is_enabled: true },
      });
      staffLabels.value = data;

      const workplaceExtension = wrappedMapGetters(root.$store, 'workplace', ['workplaceExtension']).workplaceExtension
        .value;
      changeEnabledSkill(SKILL_MAP.CUSTOM_1, workplaceExtension.custom_skill_name1 !== '');
      changeEnabledSkill(SKILL_MAP.CUSTOM_2, workplaceExtension.custom_skill_name2 !== '');
      changeEnabledSkill(SKILL_MAP.CUSTOM_3, workplaceExtension.custom_skill_name3 !== '');

      macroOperationMasters.value = await macroOperationMasterApi.index({
        workplaceId: workplaceId.value,
        budgetGroupId: budgetGroup.value.id,
        params: { is_enabled: true },
      });
    });

    // 管理グループが変更されたらシフトグループはnullにする
    watch(budgetGroup, async () => {
      macroOperationMasters.value = await macroOperationMasterApi.index({
        workplaceId: workplaceId.value,
        budgetGroupId: budgetGroup.value.id,
        params: { is_enabled: true },
      });
      macroOperationMaster.value = null;
    });

    const onClickSearchButton = async (): Promise<void> => {
      if (parseYmdDate(monthRange.value.startMonth) > parseYmdDate(monthRange.value.endMonth)) {
        notifyError1(root, '開始年月は終了年月より前の日付にしてください。');
        return;
      }

      await fetchStaffExperience({
        budget_group_id: budgetGroup.value.id,
        workplace_id: workplaceId.value,
        start_date: monthRange.value.startMonth,
        end_date: monthRange.value.endMonth,
        staff_label_id: staffLabel.value?.id ?? null,
        gender: gender.value,
        macro_operation_master_id: macroOperationMaster.value?.id ?? null,
        checked_skills: generateCheckedSkillsList(skillOptions.value),
      });
    };

    return {
      budgetGroups,
      budgetGroup,
      monthRange,
      staffLabels,
      staffLabel,
      gender,
      macroOperationMasters,
      macroOperationMaster,
      onClickSearchButton,
    };
  },
});
