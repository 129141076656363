
import Vue from 'vue'
import { defineComponent, SetupContext, reactive, PropType, onMounted, computed } from '@vue/composition-api'
import { LovEntry } from 'src/models/master'
import {
  formatDate,
  packToTimeIntegerWithGuard,
  parseYmdDate,
  timeIntegerAdd,
  timeIntegerDiff,
  unpackTimeInteger
} from 'src/util/datetime'
import { isExist } from 'src/util/isExist'
import { vvGetError, vvHasError, vvValidate } from 'src/util/vee_validate'
import ShiftDate from '../ShiftDate.vue'
import { StaffShiftConvCore } from 'src/views/Dashboard/Workplace/RegularShift/types';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { setupStartEndValidator } from 'src/hooks/customValidatorHook'
import { StaffShiftRequestParamsBase } from 'src/models/api/staffShiftRequest';
import { AttendanceSupplement, AttendanceType, ATTENDANCE_TYPE } from 'src/consts';

type ShiftEditModalState = {
  isNew: boolean
  isVisibleExtraRateSettings: boolean
  staffId: number
  staffShiftId: number
  staffName: string
  dt: string
  attendanceType: string
  attendances: LovEntry[]
  attendanceSupplement: string | null
  attendanceSupplements: LovEntry[]
  hourlyCost: number
  isChangeAtNight: boolean
  isBeginner: boolean
  memo: string | null
  scheduledStartTimeHour: string | null
  scheduledStartTimeMin: string | null
  scheduledEndTimeHour: string | null
  scheduledEndTimeMin: string | null
  scheduledBreak1StartTimeHour: string | null
  scheduledBreak1StartTimeMin: string | null
  scheduledBreak1EndTimeHour: string | null
  scheduledBreak1EndTimeMin: string | null
  scheduledBreak2StartTimeHour: string | null
  scheduledBreak2StartTimeMin: string | null
  scheduledBreak2EndTimeHour: string | null
  scheduledBreak2EndTimeMin: string | null
  scheduledBreakTime: number
  scheduledBreakTimeHour: string | null
  scheduledBreakTimeMin: string | null
  possibleOverTimeHour: string | null
  possibleOverTimeMin: string | null
  actualStartTimeHour: string | null
  actualStartTimeMin: string | null
  actualEndTimeHour: string | null
  actualEndTimeMin: string | null
  actualBreakTimeHour: string | null
  actualBreakTimeMin: string | null
  overtimeExtraRate: number
  standardWorkingHours: number | null
  holidayExtraRate: number
  midnightExtraRate: number
  midnightTimeStartHour: string | null
  midnightTimeStartMin: string | null
  midnightTimeEndHour: string | null
  midnightTimeEndMin: string | null
  overSixtyHoursExtraRate: number
  isActualEditable: boolean
}

function setupState(shiftConv: StaffShiftConvCore): ShiftEditModalState {
  const state: ShiftEditModalState = reactive({
    isNew: shiftConv.id === -1,
    isVisibleExtraRateSettings: false,
    staffId: shiftConv.staffId,
    staffShiftId: shiftConv.id,
    staffName: shiftConv.staffName,
    dt: formatDate(shiftConv.dt, 'yyyy-MM-dd'),
    attendanceType: shiftConv.attendanceType.toString(),
    attendances: [],
    attendanceSupplement: shiftConv.attendanceSupplement?.toString() ?? null,
    attendanceSupplements: [],
    hourlyCost: shiftConv.hourlyCost,
    memo: shiftConv.memo,
    isChangeAtNight: shiftConv.isChangeAtNight,
    isBeginner: shiftConv.isBeginner,

    overtimeExtraRate: shiftConv.overtimeExtraRate * 100,
    standardWorkingHours: shiftConv.standardWorkingHours !== null ? unpackTimeInteger(shiftConv.standardWorkingHours)[0] : 8,
    holidayExtraRate: shiftConv.holidayExtraRate * 100,
    midnightExtraRate: shiftConv.midnightExtraRate * 100,
    midnightTimeStart: shiftConv.midnightTimeStart,
    midnightTimeEnd: shiftConv.midnightTimeEnd,
    overSixtyHoursExtraRate: shiftConv.overSixtyHoursExtraRate * 100,

    scheduledStartTimeHour: null,
    scheduledStartTimeMin: null,
    scheduledEndTimeHour: null,
    scheduledEndTimeMin: null,
    scheduledBreak1StartTimeHour: null,
    scheduledBreak1StartTimeMin: null,
    scheduledBreak1EndTimeHour: null,
    scheduledBreak1EndTimeMin: null,
    scheduledBreak2StartTimeHour: null,
    scheduledBreak2StartTimeMin: null,
    scheduledBreak2EndTimeHour: null,
    scheduledBreak2EndTimeMin: null,
    scheduledBreakTime: computed(() => {
      const {
        scheduledBreak1StartTimeHour,
        scheduledBreak1StartTimeMin,
        scheduledBreak1EndTimeHour,
        scheduledBreak1EndTimeMin,
        scheduledBreak2StartTimeHour,
        scheduledBreak2StartTimeMin,
        scheduledBreak2EndTimeHour,
        scheduledBreak2EndTimeMin
      } = state;
      const break1Start = packToTimeIntegerWithGuard(scheduledBreak1StartTimeHour, scheduledBreak1StartTimeMin, 0)
      const break1End = packToTimeIntegerWithGuard(scheduledBreak1EndTimeHour, scheduledBreak1EndTimeMin, 0)
      const break1TimeInteger = isExist(break1Start) && isExist(break1End) ? timeIntegerDiff(break1Start, break1End) : 0
      const break2Start = packToTimeIntegerWithGuard(scheduledBreak2StartTimeHour, scheduledBreak2StartTimeMin, 0)
      const break2End = packToTimeIntegerWithGuard(scheduledBreak2EndTimeHour, scheduledBreak2EndTimeMin, 0)
      const break2TimeInteger = isExist(break2Start) && isExist(break2End) ? timeIntegerDiff(break2Start, break2End) : 0
      return timeIntegerAdd(break1TimeInteger, break2TimeInteger)
    }),
    scheduledBreakTimeHour: computed(() => unpackTimeInteger(state.scheduledBreakTime)[0].toString()),
    scheduledBreakTimeMin: computed(() => unpackTimeInteger(state.scheduledBreakTime)[1].toString().padStart(2, '0')),
    possibleOverTimeHour: null,
    possibleOverTimeMin: null,
    actualStartTimeHour: null,
    actualStartTimeMin: null,
    actualEndTimeHour: null,
    actualEndTimeMin: null,
    actualBreakTimeHour: null,
    actualBreakTimeMin: null,
    midnightTimeStartHour: null,
    midnightTimeStartMin: null,
    midnightTimeEndHour: null,
    midnightTimeEndMin: null,
    isActualEditable: computed(() => [ATTENDANCE_TYPE.NORMAL, ATTENDANCE_TYPE.HOLIDAY_WORK, ATTENDANCE_TYPE.SUBSTITUTE_ATTENDANCE].map(val => val.toString()).includes(state.attendanceType))
  })

  if (isExist(shiftConv.scheduledStartingTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.scheduledStartingTime)
    state.scheduledStartTimeHour = hour.toString()
    state.scheduledStartTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.scheduledClosingTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.scheduledClosingTime)
    state.scheduledEndTimeHour = hour.toString()
    state.scheduledEndTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.scheduledBreak1StartTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.scheduledBreak1StartTime)
    state.scheduledBreak1StartTimeHour = hour.toString()
    state.scheduledBreak1StartTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.scheduledBreak1EndTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.scheduledBreak1EndTime)
    state.scheduledBreak1EndTimeHour = hour.toString()
    state.scheduledBreak1EndTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.scheduledBreak2StartTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.scheduledBreak2StartTime)
    state.scheduledBreak2StartTimeHour = hour.toString()
    state.scheduledBreak2StartTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.scheduledBreak2EndTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.scheduledBreak2EndTime)
    state.scheduledBreak2EndTimeHour = hour.toString()
    state.scheduledBreak2EndTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.possibleOverTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.possibleOverTime)
    state.possibleOverTimeHour = hour.toString()
    state.possibleOverTimeMin = ('0' + min).slice(-2)
  }

  if (isExist(shiftConv.actualStartingTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.actualStartingTime)
    state.actualStartTimeHour = hour.toString()
    state.actualStartTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.actualClosingTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.actualClosingTime)
    state.actualEndTimeHour = hour.toString()
    state.actualEndTimeMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.actualBreakTime)) {
    const [hour, min] = unpackTimeInteger(shiftConv.actualBreakTime)
    state.actualBreakTimeHour = hour.toString()
    state.actualBreakTimeMin = ('0' + min).slice(-2)
  }

  if (isExist(shiftConv.midnightTimeStart)) {
    const [hour, min] = unpackTimeInteger(shiftConv.midnightTimeStart)
    state.midnightTimeStartHour = hour.toString()
    state.midnightTimeStartMin = ('0' + min).slice(-2)
  }
  if (isExist(shiftConv.midnightTimeEnd)) {
    const [hour, min] = unpackTimeInteger(shiftConv.midnightTimeEnd)
    state.midnightTimeEndHour = hour.toString()
    state.midnightTimeEndMin = ('0' + min).slice(-2)
  }

  return state
}

function stateToReqParamBase(state: ShiftEditModalState): StaffShiftRequestParamsBase {
  const ret: StaffShiftRequestParamsBase = {
    staff_id: state.staffId,
    dt: parseYmdDate(state.dt),
    attendance_type: (state.attendanceType as unknown) as AttendanceType,
    attendance_supplement: state.attendanceSupplement ? (state.attendanceSupplement as unknown) as AttendanceSupplement : null,
    hourly_cost: state.hourlyCost,
    is_change_at_night: state.isChangeAtNight,
    is_beginner: state.isBeginner,
    scheduled_work_start_time: null,
    scheduled_work_end_time: null,
    scheduled_break1_start_time: null,
    scheduled_break1_end_time: null,
    scheduled_break2_start_time: null,
    scheduled_break2_end_time: null,
    scheduled_break_time: null,
    possible_over_time: null,
    actual_work_start_time: null,
    actual_work_end_time: null,
    actual_break_time: null,
    overtime_extra_rate: state.overtimeExtraRate / 100,
    midnight_extra_rate: state.midnightExtraRate / 100,
    holiday_extra_rate: state.holidayExtraRate / 100,
    over_sixty_hours_extra_rate: state.overSixtyHoursExtraRate / 100,
    standard_working_hours: packToTimeIntegerWithGuard(state.standardWorkingHours, 0, 0),
    midnight_time_start: null,
    midnight_time_end: null,
    memo: state.memo,
  }

  const {
    scheduledStartTimeHour,
    scheduledStartTimeMin,
    scheduledEndTimeHour,
    scheduledEndTimeMin,
    scheduledBreak1StartTimeHour,
    scheduledBreak1StartTimeMin,
    scheduledBreak1EndTimeHour,
    scheduledBreak1EndTimeMin,
    scheduledBreak2StartTimeHour,
    scheduledBreak2StartTimeMin,
    scheduledBreak2EndTimeHour,
    scheduledBreak2EndTimeMin,
    scheduledBreakTime,
    possibleOverTimeHour,
    possibleOverTimeMin
  } = state
  ret.scheduled_work_start_time = scheduledStartTimeHour && scheduledStartTimeMin ? packToTimeIntegerWithGuard(scheduledStartTimeHour, scheduledStartTimeMin, 0) : null
  ret.scheduled_work_end_time = scheduledEndTimeHour && scheduledEndTimeMin ? packToTimeIntegerWithGuard(scheduledEndTimeHour, scheduledEndTimeMin, 0) : null
  ret.scheduled_break1_start_time = scheduledBreak1StartTimeHour && scheduledBreak1StartTimeMin ? packToTimeIntegerWithGuard(scheduledBreak1StartTimeHour, scheduledBreak1StartTimeMin, 0) : null
  ret.scheduled_break1_end_time = scheduledBreak1EndTimeHour && scheduledBreak1EndTimeMin ? packToTimeIntegerWithGuard(scheduledBreak1EndTimeHour, scheduledBreak1EndTimeMin, 0) : null
  ret.scheduled_break2_start_time = scheduledBreak2StartTimeHour && scheduledBreak2StartTimeMin ? packToTimeIntegerWithGuard(scheduledBreak2StartTimeHour, scheduledBreak2StartTimeMin, 0) : null
  ret.scheduled_break2_end_time = scheduledBreak2EndTimeHour && scheduledBreak2EndTimeMin ? packToTimeIntegerWithGuard(scheduledBreak2EndTimeHour, scheduledBreak2EndTimeMin, 0) : null
  ret.scheduled_break_time = scheduledBreakTime
  ret.possible_over_time = possibleOverTimeHour && possibleOverTimeMin ? packToTimeIntegerWithGuard(possibleOverTimeHour, possibleOverTimeMin, 0) : null

  const { actualStartTimeHour, actualStartTimeMin, actualEndTimeHour, actualEndTimeMin, actualBreakTimeHour, actualBreakTimeMin } = state
  ret.actual_work_start_time = actualStartTimeHour && actualStartTimeMin ? packToTimeIntegerWithGuard(actualStartTimeHour, actualStartTimeMin, 0) : null
  ret.actual_work_end_time = actualEndTimeHour && actualEndTimeMin ? packToTimeIntegerWithGuard(actualEndTimeHour, actualEndTimeMin, 0) : null
  ret.actual_break_time = actualBreakTimeHour && actualBreakTimeMin ? packToTimeIntegerWithGuard(actualBreakTimeHour, actualBreakTimeMin, 0) : null

  const { midnightTimeStartHour, midnightTimeStartMin, midnightTimeEndHour, midnightTimeEndMin } = state
  ret.midnight_time_start = midnightTimeStartHour && midnightTimeStartMin ? packToTimeIntegerWithGuard(midnightTimeStartHour, midnightTimeStartMin, 0) : null
  ret.midnight_time_end = midnightTimeEndHour && midnightTimeEndMin ? packToTimeIntegerWithGuard(midnightTimeEndHour, midnightTimeEndMin, 0) : null

  return ret
}

function getValidationMap() {
  const ruleTimeHour = { numeric: true, max: 2, max_value: 23 }
  const ruleTimeHourEnd = { numeric: true, max: 2, max_value: 47 }
  const ruleTimeMin = { required: false, numeric: true, max: 2, regex: /^(0|00|15|30|45)$/ }
  const ruleRate = { regex: /^([1-9][0-9]{0,2}|0)(\.[0-9])?$/ }
  return {
    hourly_cost: { numeric: true, max: 5 },
    memo: { required: false, max: 200 },

    scheduled_start_time_hour: ruleTimeHour,
    scheduled_start_time_min: ruleTimeMin,
    scheduled_end_time_hour: ruleTimeHourEnd,
    scheduled_end_time_min: ruleTimeMin,
    scheduled_break1_start_time_hour: ruleTimeHour,
    scheduled_break1_start_time_min: ruleTimeMin,
    scheduled_break1_end_time_hour: ruleTimeHour,
    scheduled_break1_end_time_min: ruleTimeMin,
    scheduled_break2_start_time_hour: ruleTimeHour,
    scheduled_break2_start_time_min: ruleTimeMin,
    scheduled_break2_end_time_hour: ruleTimeHour,
    scheduled_break2_end_time_min: ruleTimeMin,
    scheduled_break_time_hour: ruleTimeHour,
    scheduled_break_time_min: ruleTimeMin,
    possible_over_time_hour: ruleTimeHour,
    possible_over_time_min: ruleTimeMin,

    actual_start_time_hour: ruleTimeHour,
    actual_start_time_min: ruleTimeMin,
    actual_end_time_hour: ruleTimeHourEnd,
    actual_end_time_min: ruleTimeMin,
    actual_break_time_hour: ruleTimeHour,
    actual_break_time_min: ruleTimeMin,

    overtime_extra_rate: ruleRate,
    standard_working_hours: { regex: /^([1-9][0-9]{0,1}|0)(\.[0-9])?$/ },
    holiday_extra_rate: ruleRate,
    midnight_extra_rate: ruleRate,

    midnight_time_start_hour: ruleTimeHour,
    midnight_time_start_min: ruleTimeMin,
    midnight_time_end_hour: ruleTimeHourEnd,
    midnight_time_end_min: ruleTimeMin,

    over_sixty_extra_rate: ruleRate,
  }
}

export default defineComponent({
  name: 'shift-edit-modal',
  props: {
    shiftConv: {
      type: Object as PropType<StaffShiftConvCore>,
      default: null,
    },
  },
  components: {
    ShiftDate,
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue
    const state = setupState(props.shiftConv)
    setupScheduledStartEndTimeValidator(root, state)
    setupScheduledBreak1StartEndTimeValidator(root, state)
    setupScheduledBreak2StartEndTimeValidator(root, state)
    setupActualStartEndTimeValidator(root, state)
    setupMidnightTimeStartEndValidator(root, state)

    async function saveItem() {
      if (!(await vvValidate(root))) { return }

      if (state?.staffShiftId === -1) {
        context.emit('create', stateToReqParamBase(state))
      } else {
        context.emit('update', stateToReqParamBase(state))
      }
    }

    async function deleteItem() {
      context.emit('delete', stateToReqParamBase(state))
    }

    function closeShiftEditModal() {
      context.emit('close')
    }

    function getError(fieldName: string): string | null {
      return vvGetError(root, fieldName)
    }

    function hasError() {
      return vvHasError(root)
    }

    const validations = getValidationMap()

    onMounted(async() => {
      const { masters } = await ensureUserAndMasters(root)
      state.attendances = masters.lovs.attendance_type.vals_inuse
      state.attendanceSupplements = masters.lovs.attendance_supplement.vals_inuse
    })

    function setupScheduledStartEndTimeValidator(root: Vue, state: ShiftEditModalState) {
      setupStartEndValidator(root, 'scheduledStartEndTime', () => {
        const startTime = packToTimeIntegerWithGuard(state.scheduledStartTimeHour, state.scheduledStartTimeMin, 0)
        const endTime = packToTimeIntegerWithGuard(state.scheduledEndTimeHour, state.scheduledEndTimeMin, 0)
        if (!isExist(startTime) || !isExist(endTime)) return { startDate: null, endDate: null, startTime: null, endTime: null }
        const date = new Date()
        return {
          startDate: date,
          endDate: date,
          startTime,
          endTime,
        }
      })
    }

    function setupScheduledBreak1StartEndTimeValidator(root: Vue, state: ShiftEditModalState) {
      setupStartEndValidator(root, 'scheduledBreak1StartEndTime', () => {
        const startTime = packToTimeIntegerWithGuard(state.scheduledBreak1StartTimeHour, state.scheduledBreak1StartTimeMin, 0)
        const endTime = packToTimeIntegerWithGuard(state.scheduledBreak1EndTimeHour, state.scheduledBreak1EndTimeMin, 0)
        if (!isExist(startTime) || !isExist(endTime)) return { startDate: null, endDate: null, startTime: null, endTime: null }
        const date = new Date()
        return {
          startDate: date,
          endDate: date,
          startTime,
          endTime,
        }
      })
    }

    function setupScheduledBreak2StartEndTimeValidator(root: Vue, state: ShiftEditModalState) {
      setupStartEndValidator(root, 'scheduledBreak2StartEndTime', () => {
        const startTime = packToTimeIntegerWithGuard(state.scheduledBreak2StartTimeHour, state.scheduledBreak2StartTimeMin, 0)
        const endTime = packToTimeIntegerWithGuard(state.scheduledBreak2EndTimeHour, state.scheduledBreak2EndTimeMin, 0)
        if (!isExist(startTime) || !isExist(endTime)) return { startDate: null, endDate: null, startTime: null, endTime: null }
        const date = new Date()
        return {
          startDate: date,
          endDate: date,
          startTime,
          endTime,
        }
      })
    }

    function setupActualStartEndTimeValidator(root: Vue, state: ShiftEditModalState) {
      setupStartEndValidator(root, 'actualStartEndTime', () => {
        const startTime = packToTimeIntegerWithGuard(state.actualStartTimeHour, state.actualStartTimeMin, 0)
        const endTime = packToTimeIntegerWithGuard(state.actualEndTimeHour, state.actualEndTimeMin, 0)
        if (!isExist(startTime) || !isExist(endTime)) return { startDate: null, endDate: null, startTime: null, endTime: null }
        const date = new Date()
        return {
          startDate: date,
          endDate: date,
          startTime,
          endTime,
        }
      })
    }

    function setupMidnightTimeStartEndValidator(root: Vue, state: ShiftEditModalState) {
      setupStartEndValidator(root, 'midnightStartEndTime', () => {
        const startTime = packToTimeIntegerWithGuard(state.midnightTimeStartHour, state.midnightTimeStartMin, 0)
        const endTime = packToTimeIntegerWithGuard(state.midnightTimeEndHour, state.midnightTimeEndMin, 0)
        if (!isExist(startTime) || !isExist(endTime)) return { startDate: null, endDate: null, startTime: null, endTime: null }
        const date = new Date()
        return {
          startDate: date,
          endDate: date,
          startTime,
          endTime,
        }
      })
    }

    function onTimeInput(field: string) {
      vvValidate(root, field)
    }

    return {
      state,
      getError,
      hasError,
      saveItem,
      deleteItem,
      validations,
      closeShiftEditModal,
      onTimeInput,
    }
  },
})
