import { type ShiftPhase, SHIFT_PHASE, ATTENDANCE_TYPE } from 'src/consts';
import { type PreShift, type PreShiftItem, type Shift, type ShiftItem } from 'src/models/regularShift';

const { ACTUAL, SCHEDULED, PROVISIONAL } = SHIFT_PHASE;

const { TBD } = ATTENDANCE_TYPE;

type ShiftType = typeof ACTUAL | typeof SCHEDULED | typeof PROVISIONAL;

export type GenericShift = {
  hoursTotal: number;
  hoursNormal: number;
  hoursOvertime: number;
  shiftId: number;
  shiftType: ShiftType;
  attendanceType: number;
  startTime: number;
  endTime: number;
};

export type NullShift = {
  hoursTotal: 0;
  hoursNormal: 0;
  hoursOvertime: 0;
  shiftId: null;
  shiftType: ShiftType;
  attendanceType: null;
  startTime: null;
  endTime: null;
};

const _getGenericPreShift = (preShift: PreShift): GenericShift => {
  return {
    hoursTotal: Number(preShift.hours_total),
    hoursNormal: Number(preShift.hours_normal),
    hoursOvertime: Number(preShift.hours_overtime),
    shiftId: preShift.id,
    shiftType: PROVISIONAL,
    attendanceType: preShift.attendance_type,
    startTime: preShift.work_start_time,
    endTime: preShift.work_end_time,
  };
};

const _getGenericScheduledShift = (shift: Shift): GenericShift => {
  return {
    hoursTotal: Number(shift.scheduled_hours_total),
    hoursNormal: Number(shift.scheduled_hours_normal),
    hoursOvertime: Number(shift.scheduled_hours_overtime),
    shiftId: shift.id,
    shiftType: SCHEDULED,
    attendanceType: shift.scheduled_attendance_type,
    startTime: shift.scheduled_work_start_time,
    endTime: shift.scheduled_work_end_time,
  };
};

const _getGenericActualShift = (shift: Shift): GenericShift => {
  return {
    hoursTotal: Number(shift.actual_hours_total),
    hoursNormal: Number(shift.actual_hours_normal),
    hoursOvertime: Number(shift.actual_hours_overtime),
    shiftId: shift.id,
    shiftType: ACTUAL,
    attendanceType: shift.actual_attendance_type,
    startTime: shift.actual_work_start_time,
    endTime: shift.actual_work_end_time,
  };
};

const _getNullShift = (shiftType: ShiftType): NullShift => {
  return {
    hoursTotal: 0,
    hoursNormal: 0,
    hoursOvertime: 0,
    shiftId: null,
    shiftType: shiftType,
    attendanceType: null,
    startTime: null,
    endTime: null,
  };
};

const _coalesceGenericShiftValue = <T extends GenericShift | NullShift>(candidates: T[], defaultValue: T): T => {
  const invalidAttendanceType: (number | null)[] = [TBD, null];
  const formattedShift = candidates.find((e) => {
    return !invalidAttendanceType.includes(e.attendanceType);
  });

  return formattedShift || defaultValue;
};

export const getGenericShift = (
  shift: ShiftItem,
  preShift: PreShiftItem,
  displayMode: ShiftPhase,
): GenericShift | NullShift => {
  const genericPreShift = preShift.data ? _getGenericPreShift(preShift.data) : _getNullShift(PROVISIONAL);
  const genericScheduledShift = shift.data ? _getGenericScheduledShift(shift.data) : _getNullShift(SCHEDULED);
  const genericActualShift = shift.data ? _getGenericActualShift(shift.data) : _getNullShift(ACTUAL);

  const candidates: (GenericShift | NullShift)[] = [];
  switch (displayMode) {
    case PROVISIONAL:
      candidates.push(genericPreShift);
      break;
    case SCHEDULED:
      candidates.push(genericScheduledShift, genericPreShift);
      break;
    case ACTUAL:
      candidates.push(genericActualShift);
      break;
    default:
      candidates.push(_getNullShift(ACTUAL));
      break;
  }

  return _coalesceGenericShiftValue(candidates, candidates[0]);
};
