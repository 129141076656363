
import Vue from 'vue';
import { defineComponent, inject, reactive, onMounted, toRefs, computed, Ref, watch, ref } from '@vue/composition-api';
import { PageDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/usePageDisplay';
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import { SettingDisplayItemStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useSettingDisplayItem';
import { DisplayConditionStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useDisplayCondition';
import { scrollLinkage } from 'src/views/Dashboard/Workplace/RegularShift/libs/scrollLinkage';
import ShiftDateAndWeekDay from 'src/components/Workplace/ShiftDateAndWeekDay.vue';
import { SHIFT_TOTAL_MODE } from 'src/consts';
import { StaffWithShifts } from 'src/models/regularShift';
import moment from 'src/util/moment-ja';
import ShiftTotalCell from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTotalTable/ShiftTotalCell/index.vue';
import { getManHours } from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTotalTable/libs/getTotalBaseValueHelper';
import { ManHoursStateKey } from '../../hooks/useManHours';
import { BudgetGroupPlanBoardMiscsStateKey } from '../../hooks/useBudgetGroupPlanBoardMiscs';

const DISPLAY_PERIOD = 7;
const MEMO_MAX_LENGTH = 255;

const SKILLS = {
  FORK_MAN: 1,
  KEY_PLAYER: 2,
  CUSTOM_1: 3,
  CUSTOM_2: 4,
  CUSTOM_3: 5,
};

type State = {
  hasCustomSkill1: boolean;
  hasCustomSkill2: boolean;
  hasCustomSkill3: boolean;
  configuredSkillsCount: number;
  memoBaseHeight: number;
  memoHeight: number;
};

type ShiftTotalContent = {
  totalPeopleCount: number;
  totalPersonHours: number;
  requiredPersonHours: number;
};

type ShiftTotalPerDate = {
  date: string;
  general: ShiftTotalContent;
  macroOperations: ShiftTotalContent[];
};

export default defineComponent({
  name: 'shift-total-table',

  components: {
    ShiftDateAndWeekDay,
    ShiftTotalCell,
  },

  setup(_, context) {
    const root = context.root as Vue;
    const pageDisplayState = inject(PageDisplayStateKey);
    const commonState = inject(CommonStateKey);
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const settingDisplayState = inject(SettingDisplayItemStateKey);
    const displayConditionState = inject(DisplayConditionStateKey);
    const manHoursState = inject(ManHoursStateKey);
    const budgetGroupPlanBoardMiscsState = inject(BudgetGroupPlanBoardMiscsStateKey);
    const memoRef = ref<HTMLTextAreaElement[] | null>(null);
    if (
      !pageDisplayState ||
      !commonState ||
      !staffWithShiftsState ||
      !settingDisplayState ||
      !displayConditionState ||
      !manHoursState ||
      !budgetGroupPlanBoardMiscsState
    ) {
      throw new Error('State error');
    }
    const { changeShiftTotalMode, shiftTotalMode, isDisplayShiftTotalTable, changeDisplayShiftTotalTable } =
      pageDisplayState;

    const { displayMode, macroOperationMasters, changeBaseDate, baseDate, workplaceId, budgetGroup } = commonState;

    const { resetDisplayCondition } = displayConditionState;

    const {
      shouldShowDisabled,
      dateList,
      staffsWithShifts,
      onClickShiftDisplayButton,
      isDetailDisplayConditionValid,
      resetCheck,
    } = staffWithShiftsState;

    const {
      isDisplayStaffMemo,
      isDisplayStaffMemberCompany,
      isDisplaySkill,
      isDisplay7DaysTotal,
      isDisplayMonthTotal,
      isDisplayYearTotal,
    } = settingDisplayState;

    const { loadManHoursByMacroOperation } = manHoursState;

    const { loadBudgetGroupPlanBoardMiscs, budgetGroupPlanBoardMiscMemosByDate, upsertMemo } =
      budgetGroupPlanBoardMiscsState;

    const state = reactive<State>({
      hasCustomSkill1: false,
      hasCustomSkill2: false,
      hasCustomSkill3: false,
      configuredSkillsCount: 0,
      memoBaseHeight: 100,
      memoHeight: 100,
    });

    // 無効のものを表示するのフラグが切り替えられた場合、チェックボックスをリセットする
    watch([shouldShowDisabled], () => {
      resetCheck();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      // 無限ループ対策のため直前の高さとの差分が閾値を超えた場合にのみ高さを変更
      // 値が大きいほど少しづつ高さを変更した時のカクつきが大きい
      const dh = Math.abs(state.memoBaseHeight - entries[0].contentRect.height);
      const threshold = 6;
      if (dh > threshold) {
        state.memoHeight = Math.round(entries[0].contentRect.height);
        state.memoBaseHeight = state.memoHeight;
      }
    });
    onMounted(async () => {
      state.hasCustomSkill1 = !!root.$store.getters['workplace/workplaceExtension'].custom_skill_name1;
      state.hasCustomSkill2 = !!root.$store.getters['workplace/workplaceExtension'].custom_skill_name2;
      state.hasCustomSkill3 = !!root.$store.getters['workplace/workplaceExtension'].custom_skill_name3;

      const hasCustomSkills = [state.hasCustomSkill1, state.hasCustomSkill2, state.hasCustomSkill3];

      state.configuredSkillsCount = hasCustomSkills.reduce((accu: number, hasSkill: boolean) => {
        return hasSkill ? accu + 1 : accu;
      }, 0);

      memoRef.value?.forEach((value) => {
        resizeObserver.observe(value);
      });
    });

    const shiftTotalPerDates: Ref<ShiftTotalPerDate[]> = computed(() => {
      // dateListをループ
      // 対象日付はindexで対応しているので、shift内の対象indexのデータを取得
      // シフトグループ関係なく全体の合計を取得
      // シフトグループ毎の合計を取得
      return dateList.value.map((dateData) => {
        const response: ShiftTotalPerDate = {
          date: dateData.date,
          general: {
            totalPeopleCount: 0,
            totalPersonHours: 0,
            requiredPersonHours: 0,
          },
          macroOperations: [],
        };

        // macroOperation毎の集計
        response.macroOperations = macroOperationMasters.value.map((e) => {
          return {
            id: e.id,
            totalPeopleCount: 0,
            totalPersonHours: 0,
            requiredPersonHours: 0,
            is_enabled: e.is_enabled,
          };
        });

        return response;
      });
    });

    // 各スキルの人数及び人時を取得
    const calcSkillCountOrManHours = (dateIndex: number, skillType: number, isCount: boolean): number => {
      const skillCountOrMonHour = staffsWithShifts.value.reduce((accu: number, staff: StaffWithShifts) => {
        const manHours = getManHours(staff, dateIndex, displayMode.value);
        if (!manHours) {
          return accu;
        }

        switch (skillType) {
          case SKILLS.FORK_MAN:
            return staff.staff_extension.is_forkman ? (isCount ? accu + 1 : accu + manHours) : accu;
          case SKILLS.KEY_PLAYER:
            return staff.staff_extension.is_key_player ? (isCount ? accu + 1 : accu + manHours) : accu;
          case SKILLS.CUSTOM_1:
            return staff.staff_extension.has_custom_skill1 ? (isCount ? accu + 1 : accu + manHours) : accu;
          case SKILLS.CUSTOM_2:
            return staff.staff_extension.has_custom_skill2 ? (isCount ? accu + 1 : accu + manHours) : accu;
          case SKILLS.CUSTOM_3:
            return staff.staff_extension.has_custom_skill3 ? (isCount ? accu + 1 : accu + manHours) : accu;
          default:
            return accu;
        }
      }, 0);

      return Math.round(skillCountOrMonHour);
    };

    const changeShiftData = async (isPrev: boolean) => {
      const plusDateValue = isPrev ? -DISPLAY_PERIOD : DISPLAY_PERIOD;
      const targetDate = moment(baseDate.value).add(plusDateValue, 'd').toDate();

      changeBaseDate(targetDate);
      await onClickShiftDisplayButton(workplaceId.value, targetDate, budgetGroup.value.id);
      await loadManHoursByMacroOperation();
      await loadBudgetGroupPlanBoardMiscs();
      resetDisplayCondition();
      isDetailDisplayConditionValid.value = false;
    };

    async function handleBlurMemo(memo: string, dt: string) {
      if (memo.length > MEMO_MAX_LENGTH) {
        return;
      }

      await upsertMemo(memo, dt);
    }

    const hasError = (memo: string | undefined): boolean => {
      return (memo?.length ?? 0) > MEMO_MAX_LENGTH;
    };

    return {
      changeShiftTotalMode,
      shiftTotalMode,
      shouldShowDisabled,
      isDisplayShiftTotalTable,
      isDisplayStaffMemo,
      isDisplayStaffMemberCompany,
      isDisplaySkill,
      isDisplay7DaysTotal,
      isDisplayMonthTotal,
      isDisplayYearTotal,
      dateList,
      scrollLinkage,
      changeDisplayShiftTotalTable,
      ...toRefs(state),
      macroOperationMasters,
      shiftTotalPerDates,
      SKILLS,
      calcSkillCountOrManHours,
      changeShiftData,
      SHIFT_TOTAL_MODE,
      memoRef,
      handleBlurMemo,
      MEMO_MAX_LENGTH,
      budgetGroupPlanBoardMiscMemosByDate,
      hasError,
    };
  },
});
