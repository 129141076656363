import { ref, type Ref } from '@vue/composition-api';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { createInjection } from 'src/util/createInjection';

type Params = {
  workplaceId: number;
};

type InjectionValue = {
  workplaceId: number;
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
  useInReviewPhase: Ref<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useSearchConditions');

export function useSearchConditionsProvider({ workplaceId }: Params): void {
  const dt = ref(new Date());
  const budgetGroup = ref<BudgetGroup | null>(null);
  const useInReviewPhase = ref<boolean>(true);

  provide({
    workplaceId,
    dt,
    budgetGroup,
    useInReviewPhase,
  });
}

export function useSearchConditions(): InjectionValue {
  return inject();
}
