import { TimelineIndexResult } from 'src/models/timeline'
import { formatTimeInteger, packToTimeInteger, timeDifferenceInSeconds, unpackTimeInteger } from 'src/util/datetime'
import { isExist } from 'src/util/isExist'
import { ProgressHistory, TIMELINE_COLUMN_WIDTH, TimelineHeadcountData, TimelineHeadcountHourBlocks, TimelineHeader, TimelineHourBlock, TimelineState, TimelineTimetable } from './types'
import { reactive } from '@vue/composition-api'
import { ProgressHeader } from 'src/models/progressHeader'
import { TimetableMaster } from 'src/models/timetableMaster'
import { ProgressHeadcountDetail } from 'src/models/progressHeadcountDetail'
import { ProgressDetail } from 'src/models/progressDetail'
import { PlanBoardHeadcountHourBlocks } from '../PlanBoard/types'

/**
 * 1 時間内のブロック数を計算して返す
 */
export const getBlockLengthPerHour = (planBoardReponse: TimelineIndexResult) => {
  for (const progressHeader of planBoardReponse.progress_headers) {
    for (const { start_time, end_time } of progressHeader.progress_headcount_details) {
      if (!isExist(start_time) || !isExist(end_time) || start_time === 0 || end_time === 0) {
        continue
      }
      return Math.round(60 * 60 / timeDifferenceInSeconds(start_time, end_time))
    }
  }
  return 4
}

/**
 * 人時情報からその背景色を計算して返す
 */
const getHeadcountBackgroundColor = (headcount: number) => {
  if (headcount === 0) {
    return '#fff';
  }
  // headcount が大きくなるにつれて青系の色で濃くなるよう計算を行う
  // マジックナンバーは KURANDO ブルー系の色で濃淡が変わるように調整した結果の数値
  const r = Math.max(34, 203 - 6 * headcount);
  const g = Math.max(40, 227 - 6 * headcount);
  const b = Math.max(53, 254 - 6 * headcount);
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
}

/**
 * 工程の概要情報と人時情報を更新する
 */
export const updateHeaderAndBoardHourBlocks = (header: TimelineHeader, headcountHourBlocks: TimelineHeadcountHourBlocks, blockLengthPerHour: number) => {
  let lastQuantity = 0
  let headerTotalHeadcount = 0
  const boardHourBlocks: TimelineHourBlock[] = headcountHourBlocks.map((currentHeadcountList, currentHour) => {
    const headcountList = currentHeadcountList.map((headcountData, blockIndex) => {
      return {
        ...headcountData,
        backgroundColor: getHeadcountBackgroundColor(headcountData.headcount),
      }
    })
    const totalHeadcount = headcountList.reduce((total, headcountData) => total + headcountData.headcount, 0)
    const quantity = lastQuantity + totalHeadcount * (header.targetProductivity ?? 0) / blockLengthPerHour
    lastQuantity = quantity
    headerTotalHeadcount += totalHeadcount
    return {
      totalHeadcount,
      quantity,
      completionRatio: Math.min(100, Math.round(quantity / (header.resultQuantity ?? 0) * 100)),
      headcountList,
    }
  })

  return {
    header,
    boardHourBlocks,
  }
}

/**
 * API のレスポンスから工程情報を生成する
 */
export const createTimetablesFromApiResponse = ({ progress_headers }: TimelineIndexResult, blockLengthPerHour: number, displayHourPeriod: number, timetableMasters: TimetableMaster[]): TimelineTimetable[] => {
  const timetables = progress_headers
    .concat()
    .map(progressHeader => {
      const { header, boardHourBlocks } = updateHeaderAndBoardHourBlocks(
        createHeaderFromApiResponse(progressHeader, timetableMasters),
        createHeadcountHourBlocksFromApiResponse(progressHeader.progress_headcount_details, blockLengthPerHour, displayHourPeriod),
        blockLengthPerHour,
      )
      const { progressHistories, historiesStyle } = createProgressHistoriesFromApiResponse(progressHeader.progress_details)
      const timetable: TimelineTimetable = reactive({
        id: progressHeader.id,
        masterId: progressHeader.timetable_master_id,
        header,
        progressHistories,
        historiesStyle,
        boardHourBlocks,
        isChanged: false,
      })
      return timetable
    })
    .sort(({ header: a }, { header: b }) => a.dispOrder - b.dispOrder)
  return timetables
}

/**
 * API のレスポンスから工程の概要情報を生成する
 */
const createHeaderFromApiResponse = (progressHeader: ProgressHeader, timetableMasters: TimetableMaster[]): TimelineHeader => {
  const timetableMaster = timetableMasters.find((timetableMaster) => progressHeader.timetable_master_id === timetableMaster.id)
  const header: TimelineHeader = {
    name: timetableMaster?.name ?? '',
    backgroundColor: `#${timetableMaster?.disp_color ?? ''}`,
    dispOrder: timetableMaster?.disp_order ?? 0,
    resultQuantity: progressHeader.result_quantity,
    scheduledQuantity: progressHeader.scheduled_quantity,
    startTime: isExist(progressHeader.start_time) ? formatTimeInteger(progressHeader.start_time) : '',
    endTime: isExist(progressHeader.end_time) ? formatTimeInteger(progressHeader.end_time) : '',
    totalProductivity: progressHeader.total_productivity,
    targetProductivity: progressHeader.target_productivity,
    resultManHours: progressHeader.result_man_hours,
    scheduledManHours: progressHeader.scheduled_man_hours,
  }
  return header;
}

/**
 * API のレスポンスから工程の人時ブロック情報を生成する
 */
const createHeadcountHourBlocksFromApiResponse = (planBoardlocks: ProgressHeadcountDetail[], blockLengthPerHour: number, displayHourPeriod: number) => {
  const headcountHourBlocks: TimelineHeadcountHourBlocks = []
  for (let hour = 0; hour < displayHourPeriod; hour++) {
    const headcountList: TimelineHeadcountData[] = []
    for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
      const targetStartTime = packToTimeInteger(hour, Math.round(60 / blockLengthPerHour * blockIndex), 0)
      const targetBlock = planBoardlocks.find(planBoardBlock => planBoardBlock.start_time === targetStartTime)
      const headcount = targetBlock?.headcount ?? 0
      headcountList.push({
        headcount,
        backgroundColor: getHeadcountBackgroundColor(headcount),
      })
    }
    headcountHourBlocks.push(headcountList)
  }
  return headcountHourBlocks
}

/**
 * API のレスポンスから進捗履歴情報を生成する
 */
const createProgressHistoriesFromApiResponse = (progressDetails: ProgressDetail[]) => {
  const progressHistories: ProgressHistory[] = []
  const gridTemplateColumns: number[] = []
  let totalQuantity = 0

  progressDetails.forEach((data) => {
    const [sh, sm] = unpackTimeInteger(data.start_time)
    const [eh, em] = unpackTimeInteger(data.end_time)
    const startHour = (sh + sm / 60)
    const endHour = (eh + em / 60)
    const diffHour = endHour - startHour
    if (gridTemplateColumns.length === 0) {
      gridTemplateColumns.push(TIMELINE_COLUMN_WIDTH * startHour)
    }
    gridTemplateColumns.push(TIMELINE_COLUMN_WIDTH * diffHour)

    totalQuantity += data?.quantity ?? 0
    progressHistories.push({
      totalQuantity,
      productivity: data.quantity / data.man_hours,
      isHiddenInCell: diffHour < 0.5,
    })
  })
  return {
    progressHistories,
    historiesStyle: {
      display: 'grid',
      gridTemplateColumns: gridTemplateColumns.map((item) => `${item}px`).join(' ') + ' auto',
    },
  }
}

/**
 * API のレスポンスから人時ブロックの合計情報を生成する
 */
export const createTotalHeadcountHourBlocksFromApiResponse = (timetables: TimelineTimetable[], blockLengthPerHour: number, displayHourPeriod: number) => {
  const totalHeadcountHourBlocks: TimelineHeadcountHourBlocks = new Array(displayHourPeriod).fill('').map(() => {
    return new Array(blockLengthPerHour).fill('').map(() => ({ headcount: 0, totalQuantity: 0, productivity: 0, backgroundColor: '' }))
  })
  timetables.forEach(timetable => {
    timetable.boardHourBlocks.forEach(({ headcountList }, i) => {
      headcountList.forEach((headcountData, j) => {
        totalHeadcountHourBlocks[i][j].headcount += headcountData.headcount
      })
    });
  })
  totalHeadcountHourBlocks.forEach(totalHeadcountHourBlock => {
    totalHeadcountHourBlock.forEach(totalHeadcountData => {
      totalHeadcountData.backgroundColor = getHeadcountBackgroundColor(totalHeadcountData.headcount)
    })
  })
  return totalHeadcountHourBlocks
}

/**
 * API レスポンスから 要員合計 用の情報を生成する
 */
export const createHeadcountTotal = (timelineState: TimelineState, planBoardTotalHeadcountHourBlocks: PlanBoardHeadcountHourBlocks) => {
  const shift = {
    total: 0,
    targetTimeRange: 0,
  }
  const scheduled = {
    total: 0,
    targetTimeRange: 0,
  }
  const result = {
    total: 0,
    targetTimeRange: 0,
  }
  timelineState.timetables.forEach(({ header, masterId }) => {
    if (masterId < 0) { return }
    scheduled.total += header.scheduledManHours ?? 0
    result.total += header.resultManHours ?? 0
  })

  planBoardTotalHeadcountHourBlocks.forEach((totalHeadcountHourBlock) => {
    totalHeadcountHourBlock.forEach((totalHeadcount) => {
      shift.total += totalHeadcount.headcount / timelineState.blockLengthPerHour
    })
  })
  return {
    shift,
    scheduled,
    result,
  }
}
