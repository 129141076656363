
import Draggable, { MoveEvent } from 'vuedraggable';
import {
  defineComponent,
  reactive,
  SetupContext,
  getCurrentInstance,
  computed,
  watchEffect,
  ComputedRef,
} from '@vue/composition-api';
import macroOperationSegmentMasterApi from 'src/apis/macroOperationSegmentMaster';
import activityMasterApi from 'src/apis/activityMaster';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { MacroOperationSegmentMaster } from 'src/models/macroOperationSegmentMaster';
import Axios from 'axios';
import { unpackTimeIntegerToStringFormat } from 'src/util/datetime';
import { TimeInteger } from 'src/models/common';
import { ActivityMaster } from '../types';
import { isExist } from 'src/util/isExist';
import { MacroOperationSegmentMasterExternalSource } from 'src/models/api/MacroOperationSegmentMasterExternalSourceResponse';

const displayPageName = '数量区分マスタ';
type Operation = 'create' | 'update' | 'delete' | 'update_disp_order';
const msgVars: Record<Operation, string> = {
  create: '作成',
  update: '編集',
  delete: '削除',
  update_disp_order: '表示順変更',
};
const errIds = { create: 'ERR00001', update: 'ERR00002', delete: 'ERR00003', update_disp_order: 'ERR00004' };
const ValidationTargetNames = ['name', 'productivity'];
type SaveCandidate = {
  macroOperationMasterId: number;
  name: String;
  isEnabled: boolean;
  dispOrder?: number;
  productivity: number | null;
  sourceIdsForActualQuantity: number[];
  sourceIdsForActualWorkingHours: number[];
};

interface State {
  isNew: Boolean;
  macroOperationSegmentMasters: MacroOperationSegmentMaster[];
  saveCandidate: SaveCandidate;
  displayListDisabledItem: boolean;
  showSaveModal: boolean;
  deleteCandidate: MacroOperationSegmentMaster | null;
  showDeleteModal: boolean;
  selectedBudgetGroupId?: number | null;
  selectMacroOperationSegmentMasterId: number | null;
  selectedMacroOperationMasterId?: number | null;
  activitiesForSaveModal: ActivityMaster[];
}

const validations = {
  name: { required: true, max: 30 },
  productivity: { required: true, decimal: 1, max_value: 99999.9, min_value: 0 },
};

function buildInitialSaveCandidate() {
  return reactive({
    macroOperationMasterId: 0,
    name: '',
    isEnabled: true,
    dispOrder: 0,
    productivity: null,
    sourceIdsForActualQuantity: [],
    sourceIdsForActualWorkingHours: [],
  });
}
function updateCandidate(macroOperationSegmentMaster: MacroOperationSegmentMaster, state: State) {
  return reactive({
    macroOperationMasterId: macroOperationSegmentMaster.macro_operation_master_id,
    name: macroOperationSegmentMaster.name,
    isEnabled: macroOperationSegmentMaster.is_enabled,
    productivity: macroOperationSegmentMaster.productivity,
    sourceIdsForActualQuantity: macroOperationSegmentMaster.macro_operation_segment_master_external_sources
      .filter((item) => item.usage_type === 'use_for_actual_quantity')
      .map((item) => item.source_id),
    sourceIdsForActualWorkingHours: macroOperationSegmentMaster.macro_operation_segment_master_external_sources
      .filter((item) => item.usage_type === 'use_for_actual_working_hours')
      .map((item) => item.source_id),
  });
}
function setupState(): State {
  return reactive({
    isNew: false,
    macroOperationSegmentMasters: [],
    saveCandidate: buildInitialSaveCandidate(),
    showSaveModal: false,
    deleteCandidate: null,
    showDeleteModal: false,
    displayListDisabledItem: false,
    selectedBudgetGroupId: null,
    selectMacroOperationSegmentMasterId: null,
    selectedMacroOperationMasterId: null,
    activitiesForSaveModal: [],
  });
}

export default defineComponent({
  components: { Draggable },
  name: 'macro-operation-segment-master',
  props: {
    budgetGroupId: {
      type: Number,
    },
    macroOperationMasterId: {
      type: Number,
    },
    isStatusEnabled: {
      type: Boolean,
    },
  },
  setup(props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = setupState();
    const workplaceId = vueInstance.$route.params.workplaceId;
    const userId: ComputedRef<number> = wrappedMapGetters(vueInstance.$store, 'user', ['id']).id;
    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });
    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }
    function clearErrors() {
      vvReset(vueInstance);
    }
    watchEffect(async () => {
      state.displayListDisabledItem = props.isStatusEnabled;
      state.selectedBudgetGroupId = props.budgetGroupId;
      state.selectedMacroOperationMasterId = props.macroOperationMasterId;
      if (!state.selectedMacroOperationMasterId) {
        return;
      }

      state.activitiesForSaveModal = await fetchActivityMasters();
      await selectMacroOperationSegmentMasters(state.selectedBudgetGroupId!, state.selectedMacroOperationMasterId!);
    });
    // 新規データ作成モーダル
    async function openCreateModal() {
      if (!isExist(state.selectedMacroOperationMasterId)) {
        return;
      }

      const saveCandidate = buildInitialSaveCandidate();
      saveCandidate.macroOperationMasterId = state.selectedMacroOperationMasterId;
      state.isNew = true;
      state.activitiesForSaveModal = await fetchActivityMasters();
      state.saveCandidate = saveCandidate;
      state.showSaveModal = true;
      clearErrors();
    }
    async function openUpdateModal(macroOperationSegmentMaster: MacroOperationSegmentMaster) {
      if (!isExist(state.selectedMacroOperationMasterId)) {
        return;
      }

      const saveCandidate = updateCandidate(macroOperationSegmentMaster, state);
      state.selectMacroOperationSegmentMasterId = macroOperationSegmentMaster.id;
      state.isNew = false;
      state.activitiesForSaveModal = await fetchActivityMasters();
      state.saveCandidate = saveCandidate;
      state.showSaveModal = true;
      clearErrors();
    }

    // 編集モーダルclose
    function closeSaveModal(): void {
      state.saveCandidate = buildInitialSaveCandidate();
      state.showSaveModal = false;
      clearErrors();
    }

    async function _saveMacroOperationSegmentMaster() {
      if (!state.saveCandidate) {
        return;
      }
      const isValid = await Promise.all(ValidationTargetNames.map((name) => vvValidate(vueInstance, name)));
      if (isValid.some((value) => !value)) {
        return;
      }

      if (state.isNew) {
        await createMacroOperationSegmentMaster();
      } else {
        await updateMacroOperationSegmentMaster();
      }
    }

    async function createMacroOperationSegmentMaster(): Promise<void> {
      if (state.saveCandidate.productivity === null) {
        return;
      }

      const operation: Operation = 'create';
      const data = {
        name: state.saveCandidate.name,
        is_enabled: state.saveCandidate.isEnabled,
        productivity: state.saveCandidate.productivity,
        source_ids_for_actual_quantity: state.saveCandidate.sourceIdsForActualQuantity,
        source_ids_for_actual_working_hours: state.saveCandidate.sourceIdsForActualWorkingHours,
      };
      try {
        await macroOperationSegmentMasterApi.create({
          ...data,
          workplace_id: workplaceId,
          budget_group_id: state.selectedBudgetGroupId!,
          macro_operation_master_id: state.selectedMacroOperationMasterId!,
        });
        await selectMacroOperationSegmentMasters(state.selectedBudgetGroupId!, state.selectedMacroOperationMasterId!);
        closeSaveModal();
        notifySuccess1(vueInstance, `数量区分を${msgVars[operation]}しました`);
      } catch (err: any) {
        handleError(err, operation);
      }
    }

    // 数量区分update
    async function updateMacroOperationSegmentMaster(): Promise<void> {
      if (!state.selectMacroOperationSegmentMasterId) {
        return;
      }
      if (state.saveCandidate.productivity === null) {
        return;
      }
      const operation: Operation = 'update';
      const data = {
        name: state.saveCandidate.name,
        is_enabled: state.saveCandidate.isEnabled,
        productivity: state.saveCandidate.productivity,
        source_ids_for_actual_quantity: state.saveCandidate.sourceIdsForActualQuantity,
        source_ids_for_actual_working_hours: state.saveCandidate.sourceIdsForActualWorkingHours,
      };
      try {
        await macroOperationSegmentMasterApi.update({
          ...data,
          workplace_id: workplaceId,
          budget_group_id: state.selectedBudgetGroupId!,
          macro_operation_master_id: state.selectedMacroOperationMasterId!,
          macro_operation_segment_master_id: state.selectMacroOperationSegmentMasterId,
        });
        await selectMacroOperationSegmentMasters(state.selectedBudgetGroupId!, state.selectedMacroOperationMasterId!);
        closeSaveModal();
        notifySuccess1(vueInstance, `数量区分を${msgVars[operation]}しました`);
      } catch (err: any) {
        handleError(err, operation);
      }
    }

    // 数量区分 error
    function handleError(err: any, operation: Operation): void {
      if (Axios.isAxiosError(err)) {
        const errStatus = err.response!.status;
        const errRes = err.response!.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_name') {
          const msg = 'その数量区分名は既に使用されています。';
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else if (errStatus >= 400 && errStatus < 500) {
          const msg = Array.isArray(errRes) ? errRes[0] : Object.values(errRes)[0];
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          notifyCommonError(operation, err as Error);
        }
      }
    }

    // 削除モーダルopen
    function openDeleteModal(macroOperationSegmentMaster: MacroOperationSegmentMaster): void {
      state.deleteCandidate = macroOperationSegmentMaster;
      state.showDeleteModal = true;
    }

    // 削除モーダルclose
    function closeDeleteModal(): void {
      state.deleteCandidate = null;
      state.showDeleteModal = false;
    }

    // 数量区分削除
    async function _deleteMacroOperationSegmentMaster(): Promise<void> {
      if (!state.deleteCandidate) {
        return;
      }
      const operation = 'delete';
      try {
        await macroOperationSegmentMasterApi.delete({
          workplace_id: workplaceId,
          budget_group_id: state.selectedBudgetGroupId!,
          macro_operation_master_id: state.selectedMacroOperationMasterId!,
          macro_operation_segment_master_id: state.deleteCandidate.id!,
        });
        await selectMacroOperationSegmentMasters(state.selectedBudgetGroupId!, state.selectedMacroOperationMasterId!);
        closeDeleteModal();
        notifySuccess1(vueInstance, `数量区分を${msgVars[operation]}しました`);
      } catch (err) {
        if (Axios.isAxiosError(err)) {
          const errStatus = err.response!.status;
          const errRes = err.response!.data || {};
          if (errStatus === 400 && errRes.reason === 'in_use') {
            const msg = 'すでに使われているマスタです。削除できません。' + '無効化をおすすめします。';
            notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
          } else {
            notifyCommonError(operation, err as Error);
          }
        }
      }
    }

    async function _updateDispOrder() {
      const operation = 'update_disp_order';
      try {
        state.macroOperationSegmentMasters.forEach((e, i) => {
          e.disp_order = i + 1;
        });
        await macroOperationSegmentMasterApi.bulkUpdateDispOrder({
          workplace_id: workplaceId,
          budget_group_id: state.selectedBudgetGroupId!,
          macro_operation_master_id: state.selectedMacroOperationMasterId!,
          items: state.macroOperationSegmentMasters,
        });
        await selectMacroOperationSegmentMasters(state.selectedBudgetGroupId!, state.selectedMacroOperationMasterId!);
      } catch (err) {
        if (Axios.isAxiosError(err)) {
          notifyCommonError(operation, err as Error);
        }
      }
    }

    // 数量区分マスタ取得
    async function selectMacroOperationSegmentMasters(budgetGroupId: number, macroOperationMasterId: number) {
      const macroOperationSegmentMasters = await macroOperationSegmentMasterApi.index({
        workplace_id: workplaceId,
        budget_group_id: budgetGroupId,
        macro_operation_master_id: macroOperationMasterId,
        is_enabled: state.displayListDisabledItem ? undefined : true,
      });

      state.macroOperationSegmentMasters = macroOperationSegmentMasters.filter(
        (macroOperationSegmentMaster) =>
          macroOperationSegmentMaster.macro_operation_master_id === macroOperationMasterId,
      );
      state.selectedMacroOperationMasterId = macroOperationMasterId;
    }

    function isDraggable(e: MoveEvent<MacroOperationSegmentMaster>) {
      const dragged = e.draggedContext.element;
      const target = e.relatedContext.element;

      return dragged.macro_operation_master_id === target.macro_operation_master_id;
    }

    const gatedFuncGenerator = getGatedFuncGenerator();

    function notifyCommonError(operation: Operation, err?: Error) {
      const msg =
        `${displayPageName}の${msgVars[operation]}に失敗しました。` +
        '管理者に連絡してください。' +
        `(ERR: ${displayPageName} ${errIds[operation]}, user_id:${userId.value})`;
      notifyError1(vueInstance, msg, { err });
    }

    function findActivityMaster(source_id: number): ActivityMaster | null {
      return state.activitiesForSaveModal.find((activity) => activity.id === source_id) ?? null;
    }

    async function fetchActivityMasters(): Promise<ActivityMaster[]> {
      const activityMastersResponse = await activityMasterApi.index(workplaceId, state.selectedBudgetGroupId!);
      if (!isExist(activityMastersResponse)) {
        return [];
      }
      return (
        activityMastersResponse
          .filter((activityMaster) => activityMaster.is_editable)
          .map((activityMaster) => {
            const label = `${activityMaster.budget_unit.name} ${activityMaster.name}`;
            return {
              ...activityMaster,
              label,
              key: activityMaster.id,
            };
          }) ?? []
      );
    }

    const toFixed = (num: number | null | undefined, digits: number): string => {
      if (!isExist(num)) {
        return '';
      }
      return num.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    };
    const timeString = (timeInteger: TimeInteger | null): string => {
      return timeInteger !== null ? unpackTimeIntegerToStringFormat(timeInteger) : '';
    };
    const getActivityMasterLabel = (
      items: MacroOperationSegmentMasterExternalSource[],
      usage_type: MacroOperationSegmentMasterExternalSource['usage_type'],
    ): string => {
      return items
        .filter((item) => item.usage_type === usage_type)
        .map((item) => findActivityMaster(item.source_id)?.label ?? item.source_id)
        .join(', ');
    };

    return {
      state,
      validations,
      // computed
      hasError,
      // methods
      toFixed,
      getError,
      getActivityMasterLabel,
      openCreateModal,
      openUpdateModal,
      closeSaveModal,
      openDeleteModal,
      closeDeleteModal,
      saveMacroOperationSegmentMaster: gatedFuncGenerator.makeAsyncFuncGated(_saveMacroOperationSegmentMaster),
      deleteMacroOperationSegmentMaster: gatedFuncGenerator.makeAsyncFuncGated(_deleteMacroOperationSegmentMaster),
      updateDispOrder: gatedFuncGenerator.makeAsyncFuncGated(_updateDispOrder),
      findActivityMaster,
      isDraggable,
      timeString,
      dragOptions: {
        handle: '.grabbable',
        animation: 300,
      },
    };
  },
});
