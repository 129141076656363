


















import { computed, defineComponent, type PropType } from '@vue/composition-api'
import {
  SHOW_MODE,
  ShowMode,
} from 'src/consts'
import { MONTHLY_PLAN_DISPLAY_TARGET } from '../const'
import { MonthlyPlanDisplayTarget } from '../types'

const {
  INITIAL_QUANTITY,
  FINAL_QUANTITY,
  ACTUAL_QUANTITY,
  PLANNED_PRODUCTIVITY,
  ACTUAL_PRODUCTIVITY,
  REQUIRED_MAN_HOURS,
  PLANNED_MAN_HOURS,
  ACTUAL_MAN_HOURS,
} = MONTHLY_PLAN_DISPLAY_TARGET;

const allOptions: Array<{ id: MonthlyPlanDisplayTarget, name: string }> = [
  { id: INITIAL_QUANTITY, name: '数量 初期' },
  { id: FINAL_QUANTITY, name: '数量 直前' },
  { id: ACTUAL_QUANTITY, name: '数量 実績' },
  { id: PLANNED_PRODUCTIVITY, name: '生産性 予定' },
  { id: ACTUAL_PRODUCTIVITY, name: '生産性 実績' },
  { id: REQUIRED_MAN_HOURS, name: '要員 必要' },
  { id: PLANNED_MAN_HOURS, name: '要員 シフト' },
  { id: ACTUAL_MAN_HOURS, name: '要員 実績' },
]

const plannedOptions = allOptions.filter((option) => [
  INITIAL_QUANTITY,
  FINAL_QUANTITY,
  PLANNED_PRODUCTIVITY,
  REQUIRED_MAN_HOURS,
  PLANNED_MAN_HOURS,
].some((target) => target === option.id))

const actualOptions = allOptions.filter((option) => [
  ACTUAL_QUANTITY,
  ACTUAL_PRODUCTIVITY,
  ACTUAL_MAN_HOURS,
].some((target) => target === option.id))

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<{ id: MonthlyPlanDisplayTarget, name: string }[]>,
      required: true,
    },
    showMode: {
      type: Number as PropType<ShowMode | null>,
      required: true,
    }
  },
  setup(props) {
    const displayTargetOptions = computed(() => {
      if (props.showMode === SHOW_MODE.ACTUAL) {
        return actualOptions
      }
      if (props.showMode === SHOW_MODE.SCHEDULED) {
        return plannedOptions
      }
      return allOptions
    })
    return {
      displayTargetOptions,
    }
  }
})
